<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  height: 2.5em;
  width: 100%;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
.vue__time-picker {
  width: 100%;
}
</style>
<template>
  <div class="px-7 py-6" style="background-color: #e5e5e5">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>

    <v-card>
      <!-- <v-card-title height="100px" dark color="#7253cf">
        <div class="d-flex">
          <h3 style=" color: rgb(56, 34, 122);margin-left: 82px;">
            School Info
          </h3>
        </div>
      </v-card-title>-->
      <v-row no-gutters justify="end">
        <v-col cols="7" class="px-2">
          <h3 style="color: rgb(56, 34, 122)">School Info</h3>
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <v-col cols="12" sm="12" md="12">
          <v-card-text>
            <v-form ref="schoolForm" style="margin-top: 40px">
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <v-autocomplete
                    v-model="lang"
                    :items="languageList"
                    :disabled="formLoading"
                    item-text="name"
                    item-value="value"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Select Language"
                    @change="getSchoolDetails()"
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>Contact Person Name</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="contactPersonName"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>Contact Person Email</label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.required, rules.email]"
                    v-model="email"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading || isEmailExist"
                  ></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>Contact Person Phone</label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.mobile]"
                    v-model="mobile"
                    type="number"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3" justify="center">
                <v-col cols="4" class="px-2">
                  <label>School Name</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="schoolName"
                    :rules="[rules.required]"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>School Address</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school_address"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>Lead Group</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="leadGroup"
                    color="#7253CF"
                    class="formFields"
                    :rules="[rules.required]"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>Total Students</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="totalStudents"
                    color="#7253CF"
                    class="formFields"
                    type="number"
                    :disabled="formLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <label>City</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="city"
                    :rules="[rules.required]"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>State</label>
                  <v-autocomplete
                    outlined
                    dense
                    :rules="[rules.required]"
                    v-model="state"
                    :items="statesList"
                    item-text="state_name"
                    item-value="id"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>Shipping Address</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="shippingAddress"
                    :rules="[rules.required]"
                    color="#7253CF"
                    class="formFields"
                    row-height="8"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>Tracking Number</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="tracking_number"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <br />
              <br />
              <div>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Pre registration LIVE on Zoom (10
                        minutes)- To be broadcast in every classroom by teacher.
                        Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedPreregistrationDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="pre_registration_date"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm start time(s) of Kick-off Assemblies</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedKickOffDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="kickoff_date"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span> Additional Kick Off </span>
                    </label>
                    <quillEditor
                      v-model="additional_kickoff_assemblies"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm Eblast for Platinum Basketball.</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <v-menu
                      ref="menu16"
                      v-model="menu16"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formatedEblastGoldBallDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="gold_ball_eblast_date"
                        @input="menu16 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Platinum Basketball raffle LIVE on
                        Zoom (10 minutes)-To be broadcast in every classroom by
                        teacher. This happens at the midway point, 1 week after
                        kickoff. Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgoldenballDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="golden_ball_date"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />

                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm Eblast for Graduation.</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <v-menu
                      ref="menu17"
                      v-model="menu17"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formatedEblastGraduationDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="graduation_eblast_date"
                        @input="menu17 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span
                        >Confirm start time(s) for Graduation Celebration
                        assemblies:</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgraduationDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="graduation_date"
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span> Additional Graduation </span>
                    </label>
                    <quillEditor
                      v-model="additional_graduation_assemblies"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
              </div>

              <!--    <div v-if="this.campaignType == 'district'">
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Pre registration LIVE on Zoom (10
                        minutes)- To be broadcast in every classroom by teacher.
                        Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedPreregistrationDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="pre_registration_date1"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm start time(s) of Kick-off Assemblies</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedKickOffDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="kickoff_date1"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span> Additional Kick Off </span>
                    </label>
                    <quillEditor
                      v-model="additional_kickoff1"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm Eblast for Platinum Basketball.</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <v-menu
                      ref="menu16"
                      v-model="menu16"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formatedEblastGoldBallDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="gold_ball_eblast"
                        @input="menu16 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Platinum Basketball raffle LIVE on
                        Zoom (10 minutes)-To be broadcast in every classroom by
                        teacher. This happens at the midway point, 1 week after
                        kickoff. Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgoldenballDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="golden_ball_date1"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm Eblast for Graduation.</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <v-menu
                      ref="menu17"
                      v-model="menu17"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formatedEblastGraduationDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="graduation_eblast"
                        @input="menu17 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span
                        >Confirm start time(s) for Graduation Celebration
                        assemblies:</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="4" class="px-2">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgraduationDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="graduation_date1"
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="2" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                        :manual-input="true"    :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span> Additional Graduation </span>
                    </label>
                    <quillEditor
                      v-model="additional_graduation1"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
              </div>-->
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>
                    <span
                      >In two full sentences please tell us what you are raising
                      money for. For example. New playground, Field day,
                      assemblies, new equipment etc..
                    </span>
                  </label>
                  <v-textarea
                    outlined
                    dense
                    v-model="raising_money_purpose"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>
                    <span>Finalize School-wide rewards for: </span>
                    <br />
                    <span
                      >1.Reaching school-wide donation goal (We recommend 1 main
                      goal and 2 smaller targets with incentives attached)
                    </span>
                    <br />
                    <span>
                      2. Reaching 50% of students registered ** Examples of
                      Rewards: Principal gets slimed. Principal becomes human
                      sundae.
                    </span>
                    <br />
                    <span
                      >Principal wears pajamas to school, school-wide pajama
                      day, movie day, teachers do WizFit Shuffle for students,
                    </span>
                    <br />
                    <span
                      >dress as favorite sports team day, Kona Ice party.
                      DEADLINE: 1 Week prior to Student Kick-off</span
                    >
                  </label>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="top_goal"
                    label="Top Goal Condition"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="top_goal_purpose"
                    label="Top Goal Text"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="middle_goal"
                    label="Middle Goal Condition"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="middle_goal_purpose"
                    label="Middle Goal Text"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="low_goal"
                    label="Low Goal Condition"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="low_goal_purpose"
                    label="Low Goal Text"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>
                    <span>Other Incentives </span>
                  </label>
                  <quillEditor
                    v-model="other_incentive"
                    :options="editorOption"
                  />
                </v-col>
              </v-row>
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>
                    <span>Notes </span>
                  </label>
                  <quillEditor v-model="notes" :options="editorOption" />
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="8" class="px-2">
                  <label>
                    <span
                      >Optional- Schedule teacher WizFit Shuffle training zoom 1
                      wk prior to student kick-off(10 minutes). Swoop will teach
                      the WizFit shuffle and make a video to share with
                      students. Teachers will have fun!!!! 5-10 teachers would
                      be great.</span
                    >
                  </label>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="4" class="px-2">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        prepend-inner-icon="mdi-calendar-range"
                        v-model="formattedTrainingDate"
                        v-bind="attrs"
                        readonly
                        color="#7253CF"
                        class="formFields"
                        :disabled="formLoading"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      v-model="teacher_training_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4" class="px-2">
                  <vue-timepicker
                    format="hh:mm A"
                    v-model="teacher_training_time"
                    class="formFields"
                    placeholder="Time"
                    input-width="510px"
                    close-on-complete
                    :manual-input="true"
                    :minute-interval="5"
                  ></vue-timepicker>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="8" class="px-2">
                  <label>
                    <span
                      >Principal Records Video – Principal records 30 second
                      video to be incorporated into promotion video Wizards will
                      provide. Script Provided. DEADLINE: 1 Week prior to
                      Student Kick-off</span
                    >
                  </label>
                  <v-checkbox
                    color="#38A737"
                    label="Completed"
                    v-model="principal_video"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="8" class="px-2">
                  <label>
                    <span
                      >Provide teacher list on spreadsheet (use provided
                      template- in google shared folder and emailed to
                      chairperson). This will enable students to select their
                      class when registering. DEADLINE: 1 Week prior to Student
                      Kick-off</span
                    >
                  </label>
                  <span class="textField-label">
                    <v-checkbox
                      color="#38A737"
                      label="Completed"
                      v-model="teacher_list"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="8" class="px-2">
                  <label>
                    <span
                      >Principal announces WizFit Challenge to teachers and asks
                      teachers to set two classroom rewards for Classroom
                      Donation goal and 75% kids Registeration goal (Reward
                      examples: No homework on Monday, longer break on Tuesday,
                      end class early on Friday, teacher wears a purple wig for
                      one day, show a movie in class, 10 minute mask break
                      outside, an extra GoNoodle, a homework pass) **Every
                      student that registered will win a light-up Wizard
                      bracelets.**</span
                    >
                  </label>
                  <v-checkbox
                    color="#38A737"
                    label="Completed"
                    v-model="teacher_wizfit_challenge"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="8" class="px-2">
                  <label>
                    <span
                      >The 6 Challenge video episodes - Kids will be able to
                      view and complete at home through the software platform.
                      Are you planning to also show some or all episode
                      challenges in P.E. class or Homeroom so kids can do with
                      their peers? If so, please detail plan below.</span
                    >
                  </label>
                  <v-checkbox
                    color="#38A737"
                    label="Completed"
                    v-model="mark_videos_watched"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="8" class="px-2">
                  <label>
                    <span
                      >Optional: Arrange sponsorships: Upload sponsor logos &
                      have displayed on each portal page for students, parents,
                      & donors to see</span
                    >
                  </label>
                  <span class="textField-label">
                    <v-checkbox
                      color="#38A737"
                      label="Completed"
                      v-model="arrange_sponsorship"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="4" class="px-2">
                  <label>Kick Off Video</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="kickoff_video"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="2" class="px-2">
                  <label>
                    <span>Kickoff Mail</span>
                  </label>
                  <div v-if="this.kickoff_mail == true">
                    <img
                      src="@/assets/thingsToDo/greenimage.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div v-else>
                    <img
                      src="@/assets/thingsToDo/crossimg1.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-col>
                <v-col cols="2" class="px-2">
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 35px;
                    "
                    dark
                    color="#38227A"
                    @click="generateKickOffVideo()"
                    >Generate</v-btn
                  >
                  <br />
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 10px;
                    "
                    dark
                    color="#38227A"
                    @click="shortgenerateKickOffVideo()"
                    >Generate Short</v-btn
                  >
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="4" class="px-2">
                  <label>Platinum Ball Video</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="goldball_video"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>

                <v-col cols="2" class="px-2">
                  <label>
                    <span>Platinum Ball Mail</span>
                  </label>
                  <div v-if="this.goldball_mail == true">
                    <img
                      src="@/assets/thingsToDo/greenimage.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div v-else>
                    <img
                      src="@/assets/thingsToDo/crossimg1.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-col>
                <v-col cols="2" class="px-2">
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 35px;
                    "
                    dark
                    color="#38227A"
                    @click="generateGoldBallVideo()"
                    >Generate</v-btn
                  >
                  <br />
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 10px;
                    "
                    dark
                    color="#38227A"
                    @click="shortgenerateGoldBallVideo()"
                    >Generate Short</v-btn
                  >
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="4" class="px-2">
                  <label>Graduation Video</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="graduation_video"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>

                <v-col cols="2" class="px-2">
                  <label>
                    <span>Graduation Mail</span>
                  </label>
                  <div v-if="this.graduation_mail == false">
                    <img
                      src="@/assets/thingsToDo/crossimg1.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div v-else>
                    <img
                      src="@/assets/thingsToDo/greenimage.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-col>
                <v-col cols="2" class="px-2">
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 35px;
                    "
                    dark
                    color="#38227A"
                    @click="generateGraduationVideo()"
                    >Generate</v-btn
                  >
                  <br />
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 10px;
                    "
                    dark
                    color="#38227A"
                    @click="shortgenerateGraduationVideo()"
                    >Generate Short</v-btn
                  >
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-btn
                  class="submit-btn"
                  :loading="formLoading"
                  dark
                  color="#38227A"
                  @click="submitSchoolForm"
                  >Submit</v-btn
                >
                <br />
              </v-row>
              <div>
                <v-btn
                  class="text-capitalize px-5"
                  dark
                  color="#38227A"
                  style="width: 131px; font-size: 13px; height: 30px"
                  @click="StarterMail()"
                  >Notify Event Support</v-btn
                >
              </div>
              <div
                class="footerStyle"
                style="font-size: 13px; font-style: italic"
              >
                Last updated at {{ this.last_updated }}
              </div>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>

      <!-- for mobile view-->
      <v-row v-if="$vuetify.breakpoint.xsOnly">
        <v-col cols="12" sm="12" md="12">
          <v-card-text>
            <v-form ref="schoolForm" style="margin-top: 40px">
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <v-autocomplete
                    v-model="lang"
                    :items="languageList"
                    :disabled="formLoading"
                    item-text="name"
                    item-value="value"
                    outlined
                    class="formFields"
                    color="#2C1963"
                    dense
                    label="Select Language"
                    @change="getSchoolDetails()"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>Contact Person Name</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="contactPersonName"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>Contact Person Email</label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.required, rules.email]"
                    v-model="email"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="px-2">
                  <label>Contact Person Phone</label>
                  <v-text-field
                    outlined
                    dense
                    :rules="[rules.mobile]"
                    v-model="mobile"
                    type="number"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3" justify="center">
                <v-col cols="12" class="px-2">
                  <label>School Name</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="schoolName"
                    :rules="[rules.required]"
                    class="formFields"
                    color="#7253CF"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="px-2">
                  <label>School Address</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="school_address"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>Lead Group</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="leadGroup"
                    color="#7253CF"
                    class="formFields"
                    :rules="[rules.required]"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="px-2">
                  <label>Total Students</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="totalStudents"
                    color="#7253CF"
                    class="formFields"
                    type="number"
                    :disabled="formLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>City</label>
                  <v-text-field
                    outlined
                    dense
                    v-model="city"
                    :rules="[rules.required]"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="px-2">
                  <label>State</label>
                  <v-autocomplete
                    outlined
                    dense
                    :rules="[rules.required]"
                    v-model="state"
                    :items="statesList"
                    item-text="state_name"
                    item-value="id"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>Shipping Address</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="shippingAddress"
                    :rules="[rules.required]"
                    color="#7253CF"
                    class="formFields"
                    row-height="8"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>Tracking Number</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="tracking_number"
                    color="#7253CF"
                    class="formFields"
                    row-height="8"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <br />
              <br />
              <div v-if="this.campaignType == 'school'">
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Pre registration LIVE on Zoom (10
                        minutes)- To be broadcast in every classroom by teacher.
                        Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedPreregistrationDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="pre_registration_date"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span>Confirm start time(s) of Kick-off Assemblies</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedKickOffDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="kickoff_date"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span> Additional Kick Off </span>
                    </label>
                    <quillEditor
                      v-model="additional_kickoff_assemblies"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Platinum Basketball raffle LIVE on
                        Zoom (10 minutes)-To be broadcast in every classroom by
                        teacher. This happens at the midway point, 1 week after
                        kickoff. Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgoldenballDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="golden_ball_date"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Confirm start time(s) for Graduation Celebration
                        assemblies:</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgraduationDate"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="graduation_date"
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_start_time"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_end_time"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span> Additional Graduation </span>
                    </label>
                    <quillEditor
                      v-model="additional_graduation_assemblies"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
              </div>
              <div v-if="this.campaignType == 'district'">
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Pre registration LIVE on Zoom (10
                        minutes)- To be broadcast in every classroom by teacher.
                        Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedPreregistrationDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="pre_registration_date1"
                        @input="menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="pre_registration_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span>Confirm start time(s) of Kick-off Assemblies</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedKickOffDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="kickoff_date1"
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="kick_off_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span> Additional Kick Off </span>
                    </label>
                    <quillEditor
                      v-model="additional_kickoff1"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm Eblast for Platinum Basketball.</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu16"
                      v-model="menu16"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formatedEblastGoldBallDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="gold_ball_eblast"
                        @input="menu16 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Confirm Time for Platinum Basketball raffle LIVE on
                        Zoom (10 minutes)-To be broadcast in every classroom by
                        teacher. This happens at the midway point, 1 week after
                        kickoff. Zoom link provided by Wizards.</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgoldenballDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="golden_ball_date1"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="gold_ball_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="8" class="px-2">
                    <label>
                      <span>Confirm Eblast for Graduation.</span>
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu17"
                      v-model="menu17"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formatedEblastGraduationDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="graduation_eblast"
                        @input="menu17 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <br />
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Confirm start time(s) for Graduation Celebration
                        assemblies:</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <v-menu
                      ref="menu4"
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          dense
                          prepend-inner-icon="mdi-calendar-range"
                          v-model="formattedgraduationDate1"
                          v-bind="attrs"
                          readonly
                          color="#7253CF"
                          class="formFields"
                          :disabled="formLoading"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        no-title
                        v-model="graduation_date1"
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_start_time1"
                      class="formFields"
                      placeholder="Start Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <vue-timepicker
                      format="hh:mm A"
                      v-model="graduation_end_time1"
                      class="formFields"
                      placeholder="End Time"
                      close-on-complete
                      :manual-input="true"
                      :minute-interval="5"
                    ></vue-timepicker>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span> Additional Graduation </span>
                    </label>
                    <quillEditor
                      v-model="additional_graduation1"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <br />
              </div>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>
                    <span
                      >In two full sentences please tell us what you are raising
                      money for. For example. New playground, Field day,
                      assemblies, new equipment etc..
                    </span>
                  </label>
                  <v-textarea
                    outlined
                    dense
                    v-model="raising_money_purpose"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>
                    <span>Finalize School-wide rewards for: </span>
                    <br />
                    <span
                      >1.Reaching school-wide donation goal (We recommend 1 main
                      goal and 2 smaller targets with incentives attached)
                    </span>
                    <br />
                    <span>
                      2. Reaching 50% of students registered ** Examples of
                      Rewards: Principal gets slimed. Principal becomes human
                      sundae.
                    </span>
                    <br />
                    <span
                      >Principal wears pajamas to school, school-wide pajama
                      day, movie day, teachers do WizFit Shuffle for students,
                    </span>
                    <br />
                    <span
                      >dress as favorite sports team day, Kona Ice party.
                      DEADLINE: 1 Week prior to Student Kick-off</span
                    >
                  </label>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="top_goal"
                    label="Top Goal Condition"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="top_goal_purpose"
                    label="Top Goal Text"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="middle_goal"
                    label="Middle Goal Condition"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="middle_goal_purpose"
                    label="Middle Goal Text"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="low_goal"
                    label="Low Goal Condition"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" class="px-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="low_goal_purpose"
                    label="Low Goal Text"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>
                    <span>Other Incentives </span>
                  </label>
                  <quillEditor
                    v-model="other_incentive"
                    :options="editorOption"
                  />
                </v-col>
              </v-row>
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>
                    <span>Notes </span>
                  </label>
                  <quillEditor v-model="notes" :options="editorOption" />
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <label>
                    <span
                      >Optional- Schedule teacher WizFit Shuffle training zoom 1
                      wk prior to student kick-off(10 minutes). Swoop will teach
                      the WizFit shuffle and make a video to share with
                      students. Teachers will have fun!!!! 5-10 teachers would
                      be great.</span
                    >
                  </label>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="12" class="px-2">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        dense
                        prepend-inner-icon="mdi-calendar-range"
                        v-model="formattedTrainingDate"
                        v-bind="attrs"
                        readonly
                        color="#7253CF"
                        class="formFields"
                        :disabled="formLoading"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      no-title
                      v-model="teacher_training_date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="px-2">
                  <vue-timepicker
                    format="hh:mm A"
                    v-model="teacher_training_time"
                    class="formFields"
                    placeholder="Time"
                    input-width="510px"
                    close-on-complete
                    :manual-input="true"
                    :minute-interval="5"
                  ></vue-timepicker>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>
                    <span
                      >Principal Records Video – Principal records 30 second
                      video to be incorporated into promotion video Wizards will
                      provide. Script Provided. DEADLINE: 1 Week prior to
                      Student Kick-off</span
                    >
                  </label>
                  <v-checkbox
                    color="#38A737"
                    label="Completed"
                    v-model="principal_video"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>
                    <span
                      >Provide teacher list on spreadsheet (use provided
                      template- in google shared folder and emailed to
                      chairperson). This will enable students to select their
                      class when registering. DEADLINE: 1 Week prior to Student
                      Kick-off</span
                    >
                  </label>
                  <span class="textField-label">
                    <v-checkbox
                      color="#38A737"
                      label="Completed"
                      v-model="teacher_list"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>
                    <span
                      >Principal announces WizFit Challenge to teachers and asks
                      teachers to set two classroom rewards for Classroom
                      Donation goal and 75% kids Registeration goal (Reward
                      examples: No homework on Monday, longer break on Tuesday,
                      end class early on Friday, teacher wears a purple wig for
                      one day, show a movie in class, 10 minute mask break
                      outside, an extra GoNoodle, a homework pass) **Every
                      student that registered will win a light-up Wizard
                      bracelets.**</span
                    >
                  </label>
                  <v-checkbox
                    color="#38A737"
                    label="Completed"
                    v-model="teacher_wizfit_challenge"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>
                    <span
                      >The 6 Challenge video episodes - Kids will be able to
                      view and complete at home through the software platform.
                      Are you planning to also show some or all episode
                      challenges in P.E. class or Homeroom so kids can do with
                      their peers? If so, please detail plan below.</span
                    >
                  </label>
                  <v-checkbox
                    color="#38A737"
                    label="Completed"
                    v-model="mark_videos_watched"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>
                    <span
                      >Optional: Arrange sponsorships: Upload sponsor logos &
                      have displayed on each portal page for students, parents,
                      & donors to see</span
                    >
                  </label>
                  <span class="textField-label">
                    <v-checkbox
                      color="#38A737"
                      label="Completed"
                      v-model="arrange_sponsorship"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>Kick Off Video</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="kickoff_video"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="4" class="px-2">
                  <label>
                    <span>Kickoff Mail</span>
                  </label>
                  <div v-if="this.kickoff_mail == true">
                    <img
                      src="@/assets/thingsToDo/greenimage.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div v-else>
                    <img
                      src="@/assets/thingsToDo/crossimg1.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 35px;
                    "
                    dark
                    color="#38227A"
                    @click="generateKickOffVideo()"
                    >Generate</v-btn
                  >
                  <br />
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 10px;
                    "
                    dark
                    color="#38227A"
                    @click="shortgenerateKickOffVideo()"
                    >Generate Short</v-btn
                  >
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>Platinum Ball Video</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="goldball_video"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>

                <v-col cols="4" class="px-2">
                  <label>
                    <span>Platinum Ball Mail</span>
                  </label>
                  <div v-if="this.goldball_mail == true">
                    <img
                      src="@/assets/thingsToDo/greenimage.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div v-else>
                    <img
                      src="@/assets/thingsToDo/crossimg1.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 35px;
                    "
                    dark
                    color="#38227A"
                    @click="generateGoldBallVideo()"
                    >Generate</v-btn
                  >
                  <br />
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 10px;
                    "
                    dark
                    color="#38227A"
                    @click="shortgenerateGoldBallVideo()"
                    >Generate Short</v-btn
                  >
                </v-col>
              </v-row>

              <v-row
                no-gutters
                justify="center"
                v-if="this.adminAccess === 'Super Admin'"
              >
                <v-col cols="12" class="px-2">
                  <label>Graduation Video</label>
                  <v-textarea
                    outlined
                    dense
                    v-model="graduation_video"
                    color="#7253CF"
                    class="formFields"
                    :disabled="formLoading"
                  >
                  </v-textarea>
                </v-col>

                <v-col cols="4" class="px-2">
                  <label>
                    <span>Graduation Mail</span>
                  </label>
                  <div v-if="this.graduation_mail == false">
                    <img
                      src="@/assets/thingsToDo/crossimg1.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  <div v-else>
                    <img
                      src="@/assets/thingsToDo/greenimage.png"
                      width="20px"
                      height="20px"
                    />
                  </div>
                </v-col>
                <v-col cols="4" class="px-2">
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 35px;
                    "
                    dark
                    color="#38227A"
                    @click="generateGraduationVideo()"
                    >Generate</v-btn
                  >
                  <br />
                  <v-btn
                    class="text-capitalize px-5"
                    height="35px"
                    style="
                      border-radius: 10px;
                      position: unset;
                      margin-top: 10px;
                    "
                    dark
                    color="#38227A"
                    @click="shortgenerateGraduationVideo()"
                    >Generate Short</v-btn
                  >
                </v-col>
              </v-row>
              <br />
              <br />
              <v-row no-gutters justify="center">
                <v-btn
                  class="submit-btn"
                  :loading="formLoading"
                  dark
                  color="#38227A"
                  @click="submitSchoolForm"
                  >Submit</v-btn
                >
                <br />
              </v-row>
              <br />
              <div>
                <v-btn
                  class="text-capitalize px-5"
                  dark
                  color="#38227A"
                  style="width: 131px; font-size: 13px; height: 30px"
                  @click="StarterMail()"
                  >Notify Event Support</v-btn
                >
              </div>
              <div
                class="footerStyle"
                style="font-size: 13px; font-style: italic"
              >
                Last updated at {{ this.last_updated }}
              </div>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { mapActions, mapGetters } from "vuex";
import { SALES_REP } from "@/constants/ModuleKeys";
import moment from "moment";
import Helper from "@/helper";
import RULES from "@/common/fieldRules";
import {
  API_MASTER_GET_STATES_LIST,
  API_USER_GET_SALES_REP_LIST,
  API_ADMIN_UPDATE_SCHOOL,
  API_ADMIN_GET_SCHOOL_DETAIL,
  API_USER_GET_DISTRICT_LIST,
  API_MASTER_GET_MESSAGE,
  API_KICKOFF_VIDEO_GET,
  API_GOLDBALL_VIDEO_GET,
  API_GRADUATION_VIDEO_GET,
  API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
  API_ADMIN_CAMPAIGN_MANAGEMENT_UPDATE,
  API_ADMIN_GET_STARTER_KIT_MAIL,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddEditViewSchoolModal",
  components: { VueTimepicker, quillEditor },
  props: ["DistrictFormData"],
  data() {
    return {
    isEmailExist:false,
      adminAccess: localStorage.getItem("adminAccess"),
      rules: RULES,
      loading: false,
      loading1: false,
      formLoading: false,
      previewImage: null,
      LogoImgFile: {},
      schoolName: "",
      school_address: "",
      salesRep: null,
      salesRepList: [],
      city: "",
      state: null,
      statesList: [],
      contactPersonName: "",
      role: "",
      email: "",
      mobile: null,
      shippingAddress: "",
      tracking_number: "",
      leadGroup: "",
      district: -1,
      totalStudents: null,
      districtList: [],
      SpecialMessage: "",
      short_school_name: "",
      totalcharacter: 0,
      maxchar: 20,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu16: false,
      menu17: false,
      low_goal: "",
      low_goal_purpose: "",
      top_goal: "",
      top_goal_purpose: "",
      middle_goal: "",
      middle_goal_purpose: "",
      pre_registration_date: "",
      pre_registration_start_time: "08:00 AM",
      pre_registration_end_time: "08:00 AM",
      kickoff_date: "",
      kick_off_start_time: "08:00 AM",
      kick_off_end_time: "08:00 AM",
      golden_ball_date: "",
      gold_ball_start_time: "08:00 AM",
      gold_ball_end_time: "08:00 AM",
      graduation_start_time: "08:00 AM",
      graduation_end_time: "08:00 AM",
      graduation_date: "",
      pre_registration_date1: "",
      pre_registration_start_time1: "08:00 AM",
      pre_registration_end_time1: "08:00 AM",
      kickoff_date1: "",
      kick_off_start_time1: "08:00 AM",
      kick_off_end_time1: "08:00 AM",
      golden_ball_date1: "",
      gold_ball_start_time1: "08:00 AM",
      gold_ball_end_time1: "08:00 AM",
      graduation_start_time1: "08:00 AM",
      graduation_end_time1: "08:00 AM",
      graduation_date1: "",
      additional_kickoff1: "",
      additional_graduation1: "",
      raising_money_purpose: "",
      principal_video: false,
      teacher_wizfit_challenge: false,
      teacher_training_date: "",
      teacher_training_time: "",
      teacher_list: false,
      video_episodes_plan: "",
      mark_videos_watched: false,
      arrange_sponsorship: false,
      notes: "",
      other_incentive: "",
      kickoff_mail: false,
      goldball_mail: false,
      graduation_mail: false,
      kickoff_video: "",
      goldball_video: "",
      graduation_video: "",
      last_updated: "",
      additional_kickoff_assemblies: "",
      additional_graduation_assemblies: "",
      gold_ball_eblast_date: "",
      graduation_eblast_date: "",
      gold_ball_eblast: "",
      graduation_eblast: "",
      campaignType: "",
      editorOption: {
        readOnly: true,
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            //   [{ script: "sub" }, { script: "super" }],
            //   [{ indent: "-1" }, { indent: "+1" }],
            //   [{ size: ["small", false, "large", "huge"] }],
            //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            //   [{ font: [] }],
            [{ align: [] }],
            [{ direction: "rtl" }], // text direction
            ["clean"],
          ],
        },
      },
      lang: "en",
      languageList: [
        { name: "English", value: "en" },
        { name: "Spanish", value: "es" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "schoolManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewSchoolModal({ show: value });
      },
    },
    type() {
      return this.$store.state.schoolManagement.addEditViewModal.type;
    },
    districtID() {
      return this.$store.state.schoolManagement.addEditViewModal.districtID;
    },
    toastMessage() {
      return "School Info Added";
    },
    dialogTitle() {
      return "Add new School";
    },
    formattedPreregistrationDate: {
      get() {
        if (this.pre_registration_date) {
          return moment(this.pre_registration_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedPreregistrationDate1: {
      get() {
        if (this.pre_registration_date1) {
          return moment(this.pre_registration_date1).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedKickOffDate: {
      get() {
        if (this.kickoff_date) {
          return moment(this.kickoff_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedKickOffDate1: {
      get() {
        if (this.kickoff_date1) {
          return moment(this.kickoff_date1).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgoldenballDate: {
      get() {
        if (this.golden_ball_date) {
          return moment(this.golden_ball_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEblastGoldBallDate: {
      get() {
        if (this.gold_ball_eblast_date) {
          return moment(this.gold_ball_eblast_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEblastGoldBallDate1: {
      get() {
        if (this.gold_ball_eblast) {
          return moment(this.gold_ball_eblast).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEblastGraduationDate: {
      get() {
        if (this.graduation_eblast_date) {
          return moment(this.graduation_eblast_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formatedEblastGraduationDate1: {
      get() {
        if (this.graduation_eblast) {
          return moment(this.graduation_eblast).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgoldenballDate1: {
      get() {
        if (this.golden_ball_date1) {
          return moment(this.golden_ball_date1).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgraduationDate: {
      get() {
        if (this.graduation_date) {
          return moment(this.graduation_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgraduationDate1: {
      get() {
        if (this.graduation_date1) {
          return moment(this.graduation_date1).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedTrainingDate: {
      get() {
        if (this.teacher_training_date) {
          return moment(this.teacher_training_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },

  methods: {
    ...mapActions({
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    onDistrictChangeHandler(value) {
      var selectedDistrict = this.districtList.filter(
        (district) => district.id === value
      )[0];
      this.city = selectedDistrict.city_name;
      this.state = selectedDistrict.state_id;
    },
    /**
     * GET State List
     */
    getStateList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.state_list);
        self.statesList = data.state_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getSalesRepList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_MASTER_GET_STATES_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET Sales-Rep List
     */
    getSalesRepList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.sales_rep_list);
        self.salesRepList = data.sales_rep_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getDistrictList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },

    /**
     * GET District List
     */
    getDistrictList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.district_list);
        self.districtList = data.district_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.setFormDataParams();
        self.getSpecialMessage();
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_DISTRICT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getSpecialMessage() {
      const successHandler = (res) => {
        // console.log(res,"special message")
        this.SpecialMessage = res.data.special_message_list[0].message;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["message_for"] = "school";
      formData["message_type"] = "special_message";
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getSchoolDetails() {
      const self = this;
      const successHandler = (res) => {
        let data = res.data;
        this.schoolName = data.school_detail.school_name;
        this.school_address = data.school_detail.school_address;
        this.city = data.school_detail.city_name;
        this.contactPersonName = data.school_detail.user_name;
        this.email = data.school_detail.user_email;
       
        if (this.email) {
        
        this.isEmailExist = true; // Disable email field if it exists
      }
        this.mobile = data.school_detail.user_phone;
        this.state = data.school_detail.state;
        this.previewImage = data.school_detail.logo_url;
        this.shippingAddress = data.school_detail.shipping_address;
        this.tracking_number = data.school_detail.tracking_number;
        this.leadGroup = data.school_detail.lead_group;
        this.district = data.school_detail.district;
        this.totalStudents = data.school_detail.total_student;
        this.kickoff_mail = data.school_detail.kickoff_mail;
        this.goldball_mail = data.school_detail.goldball_mail;
        this.graduation_mail = data.school_detail.graduation_mail;
        if (data.school_detail.kickoff_video) {
          this.kickoff_video = data.school_detail.kickoff_video;
        }
        if (data.school_detail.goldball_video) {
          this.goldball_video = data.school_detail.goldball_video;
        }
        if (data.school_detail.graduation_video) {
          this.graduation_video = data.school_detail.graduation_video;
        }
        if (data.school_detail.raising_money_purpose) {
          this.raising_money_purpose = data.school_detail.raising_money_purpose;
        }
        this.low_goal = data.school_detail.low_goal;
        this.low_goal_purpose = data.school_detail.low_goal_purpose;
        this.middle_goal = data.school_detail.middle_goal;
        this.middle_goal_purpose = data.school_detail.middle_goal_purpose;
        this.top_goal = data.school_detail.top_goal;
        this.top_goal_purpose = data.school_detail.top_goal_purpose;
        this.principal_video = data.school_detail.principal_video;
        this.notes = data.school_detail.notes;
        this.other_incentive = data.school_detail.other_incentive;
        this.teacher_wizfit_challenge =
          data.school_detail.teacher_wizfit_challenge;
        this.teacher_training_date = data.school_detail.teacher_training_date;
        this.teacher_list = data.school_detail.teacher_list;
        //  this.video_episodes_plan = data.school_detail.video_episodes_plan;
        this.mark_videos_watched = data.school_detail.mark_videos_watched;

        this.arrange_sponsorship = data.school_detail.arrange_sponsorship;
        if (data.school_detail.teacher_training_time) {
          this.teacher_training_time = Helper.convertFrom24To12Format(
            data.school_detail.teacher_training_time
          );
        }
        if (data.school_detail.last_updated) {
          this.last_updated = data.school_detail.last_updated;
        }
        if (data.school_detail.pre_registration_start_time) {
          this.pre_registration_start_time1 = Helper.convertFrom24To12Format(
            data.school_detail.pre_registration_start_time
          );
        }
        if (data.school_detail.pre_registration_end_time) {
          this.pre_registration_end_time1 = Helper.convertFrom24To12Format(
            data.school_detail.pre_registration_end_time
          );
        }
        if (data.school_detail.golden_ball_start_time) {
          this.gold_ball_start_time1 = Helper.convertFrom24To12Format(
            data.school_detail.golden_ball_start_time
          );
        }
        if (data.school_detail.golden_ball_end_time) {
          this.gold_ball_end_time1 = Helper.convertFrom24To12Format(
            data.school_detail.golden_ball_end_time
          );
        }
        if (data.school_detail.graduation_starttime) {
          this.graduation_start_time1 = Helper.convertFrom24To12Format(
            data.school_detail.graduation_starttime
          );
        }
        if (data.school_detail.graduation_endtime) {
          this.graduation_end_time1 = Helper.convertFrom24To12Format(
            data.school_detail.graduation_endtime
          );
        }
        if (data.school_detail.kickoff_starttime) {
          this.kick_off_start_time1 = Helper.convertFrom24To12Format(
            data.school_detail.kickoff_starttime
          );
        }
        if (data.school_detail.kickoff_endtime) {
          this.kick_off_end_time1 = Helper.convertFrom24To12Format(
            data.school_detail.kickoff_endtime
          );
        }
        if (data.school_detail.additional_kickoff) {
          this.additional_kickoff1 = data.school_detail.additional_kickoff;
        }
        if (data.school_detail.additional_graduation) {
          this.additional_graduation1 =
            data.school_detail.additional_graduation;
        }
        this.pre_registration_date1 = data.school_detail.pre_registration_date;

        this.golden_ball_date1 = data.school_detail.golden_ball_date;
        this.kickoff_date1 = data.school_detail.kickoff_date;
        this.graduation_date1 = data.school_detail.graduation_date;
        this.gold_ball_eblast = data.school_detail.gold_ball_eblast;
        this.graduation_eblast = data.school_detail.graduation_eblast;

        self.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        self.loading = false;
      };

      let formData = {};
      formData["school_id"] = this.$route.query.school;
      formData["lang"] = this.lang;
      console.log({ formData });

      /**
       * API Call for GET school detail
       */
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    submitSchoolForm() {
      if (this.campaignType == "school") {
        this.postCallAxios();
      }
      if (this.campaignType == "district") {
        this.postCallAxiosDistrict();
      }
      if (this.$refs.schoolForm.validate()) {
        const self = this;
        self.formLoading = true;
        // console.log("Form validated");

        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          self.formLoading = false;
          //this.$emit("reload");
          location.reload();
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };

        const failureHandler = (res) => {
          // console.log("Failure ", res);
          this.showToast({
            message: res,
            color: "e",
          });
          self.formLoading = false;
        };

        let formData = new FormData();
        formData.append(
          "school_id",
          this.selectedCampaignData.organizationData.id
        );
        formData.append("school_name", this.schoolName);
        formData.append("school_address", this.school_address);
        formData.append("user_name", this.contactPersonName);
        formData.append("user_email", this.email);
        if (this.mobile) formData.append("user_phone", this.mobile);
        formData.append("city_name", this.city);
        formData.append("state_id", this.state);
        formData.append("lead_group", this.leadGroup);
        formData.append("shipping_address", this.shippingAddress);
        formData.append("tracking_number", this.tracking_number);
        formData.append("total_student", this.totalStudents);
        formData.append("low_goal", this.low_goal);
        formData.append("low_goal_purpose", this.low_goal_purpose);
        formData.append("top_goal", this.top_goal);
        formData.append("top_goal_purpose", this.top_goal_purpose);
        formData.append("middle_goal", this.middle_goal);
        formData.append("middle_goal_purpose", this.middle_goal_purpose);
        formData.append("raising_money_purpose", this.raising_money_purpose);
        formData.append("principal_video", this.principal_video);
        formData.append("notes", this.notes);
        if (this.other_incentive) {
          formData.append("other_incentive", this.other_incentive);
        }
        formData.append(
          "teacher_wizfit_challenge",
          this.teacher_wizfit_challenge
        );
        formData.append("teacher_list", this.teacher_list);
        formData.append("mark_videos_watched", this.mark_videos_watched);
        formData.append("arrange_sponsorship", this.arrange_sponsorship);
        if (this.kickoff_video) {
          formData.append("kickoff_video", this.kickoff_video);
        }
        if (this.goldball_video) {
          formData.append("goldball_video", this.goldball_video);
        }
        if (this.graduation_video) {
          formData.append("graduation_video", this.graduation_video);
        }
        if (this.kick_off_start_time1) {
          formData.append(
            "kickoff_starttime",
            Helper.convertTime12To24(this.kick_off_start_time1)
          );
        }

        if (this.kick_off_end_time1) {
          formData.append(
            "kickoff_endtime",
            Helper.convertTime12To24(this.kick_off_end_time1)
          );
        }
        if (this.gold_ball_start_time1) {
          formData.append(
            "golden_ball_start_time",
            Helper.convertTime12To24(this.gold_ball_start_time1)
          );
        }
        if (this.gold_ball_end_time1) {
          formData.append(
            "golden_ball_end_time",
            Helper.convertTime12To24(this.gold_ball_end_time1)
          );
        }
        if (this.graduation_start_time1) {
          formData.append(
            "graduation_starttime",
            Helper.convertTime12To24(this.graduation_start_time1)
          );
        }
        if (this.graduation_end_time1) {
          formData.append(
            "graduation_endtime",
            Helper.convertTime12To24(this.graduation_end_time1)
          );
        }
        if (this.formattedKickOffDate1) {
          formData.append("kickoff_date", this.formattedKickOffDate1);
        }

        if (this.formattedPreregistrationDate1) {
          formData.append(
            "pre_registration_date",
            this.formattedPreregistrationDate1
          );
        }
        if (this.pre_registration_start_time1) {
          formData.append(
            "pre_registration_start_time",
            Helper.convertTime12To24(this.pre_registration_start_time1)
          );
        }
        if (this.pre_registration_end_time1) {
          formData.append(
            "pre_registration_end_time",
            Helper.convertTime12To24(this.pre_registration_end_time1)
          );
        }
        if (this.formattedgoldenballDate1) {
          formData.append("golden_ball_date", this.formattedgoldenballDate1);
        }
        if (this.formatedEblastGoldBallDate1) {
          formData.append("gold_ball_eblast", this.formatedEblastGoldBallDate1);
        }
        if (this.formatedEblastGraduationDate1) {
          formData.append(
            "graduation_eblast",
            this.formatedEblastGraduationDate1
          );
        }
        if (this.formattedgraduationDate1) {
          formData.append("graduation_date", this.formattedgraduationDate1);
        }

        if (this.formattedTrainingDate) {
          formData.append("teacher_training_date", this.formattedTrainingDate);
        }
        if (this.additional_kickoff1) {
          formData.append("additional_kickoff", this.additional_kickoff1);
        }
        if (this.additional_graduation1) {
          formData.append("additional_graduation", this.additional_graduation1);
        }
        if (this.teacher_training_time) {
          formData.append(
            "teacher_training_time",
            Helper.convertTime12To24(this.teacher_training_time)
          );
        }
        formData.append("lang", this.lang);
        Axios.request_PATCH(
          API_ADMIN_UPDATE_SCHOOL,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    generateGraduationVideo() {
      const self = this;
      self.formLoading = true;
      // console.log("Form validated");

      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        this.showToast({
          message: "Successfully generated",
          color: "s",
        });
        self.formLoading = false;
      };

      const failureHandler = (res) => {
        // console.log("Failure ", res);
        this.showToast({
          message: res,
          color: "e",
        });
        self.formLoading = false;
      };

      let dataTableParams = {};
      dataTableParams.school_id = this.selectedCampaignData.organizationData.id;
      dataTableParams.lang = this.lang;
      Axios.request_GET(
        API_GRADUATION_VIDEO_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    shortgenerateGraduationVideo() {
      const self = this;
      self.formLoading = true;
      // console.log("Form validated");

      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        this.showToast({
          message: "Successfully generated",
          color: "s",
        });
        self.formLoading = false;
      };

      const failureHandler = (res) => {
        // console.log("Failure ", res);
        this.showToast({
          message: res,
          color: "e",
        });
        self.formLoading = false;
      };

      let dataTableParams = {};
      dataTableParams.school_id = this.selectedCampaignData.organizationData.id;
      dataTableParams.email_type = "short";
      dataTableParams.lang = this.lang;
      Axios.request_GET(
        API_GRADUATION_VIDEO_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    generateKickOffVideo() {
      const self = this;
      self.formLoading = true;
      // console.log("Form validated");

      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        this.showToast({
          message: "Successfully generated",
          color: "s",
        });
        self.formLoading = false;
      };

      const failureHandler = (res) => {
        // console.log("Failure ", res);
        this.showToast({
          message: res,
          color: "e",
        });
        self.formLoading = false;
      };

      let dataTableParams = {};
      dataTableParams.school_id = this.selectedCampaignData.organizationData.id;
      dataTableParams.lang = this.lang;
      Axios.request_GET(
        API_KICKOFF_VIDEO_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    shortgenerateKickOffVideo() {
      const self = this;
      self.formLoading = true;
      // console.log("Form validated");

      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        this.showToast({
          message: "Successfully generated",
          color: "s",
        });
        self.formLoading = false;
      };

      const failureHandler = (res) => {
        // console.log("Failure ", res);
        this.showToast({
          message: res,
          color: "e",
        });
        self.formLoading = false;
      };

      let dataTableParams = {};
      dataTableParams.school_id = this.selectedCampaignData.organizationData.id;
      dataTableParams.lang = this.lang;
      dataTableParams.email_type = "short";
      Axios.request_GET(
        API_KICKOFF_VIDEO_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    generateGoldBallVideo() {
      const self = this;
      self.formLoading = true;
      // console.log("Form validated");

      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        this.showToast({
          message: "Successfully generated",
          color: "s",
        });
        self.formLoading = false;
      };

      const failureHandler = (res) => {
        // console.log("Failure ", res);
        this.showToast({
          message: res,
          color: "e",
        });
        self.formLoading = false;
      };

      let dataTableParams = {};
      dataTableParams.school_id = this.selectedCampaignData.organizationData.id;
      dataTableParams.lang = this.lang;
      Axios.request_GET(
        API_GOLDBALL_VIDEO_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    shortgenerateGoldBallVideo() {
      const self = this;
      self.formLoading = true;
      // console.log("Form validated");

      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        this.showToast({
          message: "Successfully generated",
          color: "s",
        });
        self.formLoading = false;
      };

      const failureHandler = (res) => {
        // console.log("Failure ", res);
        this.showToast({
          message: res,
          color: "e",
        });
        self.formLoading = false;
      };

      let dataTableParams = {};
      dataTableParams.school_id = this.selectedCampaignData.organizationData.id;
      dataTableParams.lang = this.lang;
      dataTableParams.email_type = "short";
      Axios.request_GET(
        API_GOLDBALL_VIDEO_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    setFormDataParams() {
      if (this.$route.params["id"]) {
        console.log(this.$route.params["id"]);
        this.district = this.$route.params["id"];
        var selectedDistrict = this.districtList.filter(
          (district) => district.id === this.district
        )[0];
        this.city = selectedDistrict.city_name;
        this.state = selectedDistrict.state_id;
        this.salesRep = this.$route.params[SALES_REP];
        // console.log("in toggle");
      }
    },
    charCount: function () {
      if (this.short_school_name.length > this.maxcharacter) {
        alert("max char limit is 30 character");
        return "";
      } else {
        this.totalcharacter = this.short_school_name.length;
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    getCampaignDetail() {
      const self = this;
      const successHandler = function (res) {
        const data = res.data;
        // console.log(data);
        self.kickoff_date = data.campaign_detail.kick_off_date;
        self.graduation_date = data.campaign_detail.graduation_celebration_date;
        self.golden_ball_date = data.campaign_detail.gold_ball_giveaway_date;
        self.pre_registration_date = data.campaign_detail.pre_registration_date;
        self.gold_ball_eblast_date = data.campaign_detail.gold_ball_eblast_date;
        self.graduation_eblast_date =
          data.campaign_detail.graduation_eblast_date;
        self.schoolGoal = data.campaign_detail.school_default_goal;
        self.additional_kickoff_assemblies =
          data.campaign_detail.additional_kickoff_assemblies;
        self.additional_graduation_assemblies =
          data.campaign_detail.additional_graduation_assemblies;
        if (data.campaign_detail.pre_registration_start_time) {
          self.pre_registration_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_start_time
          );
        }
        if (data.campaign_detail.pre_registration_end_time) {
          self.pre_registration_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_end_time
          );
        }
        if (data.campaign_detail.kick_off_start_time) {
          self.kick_off_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_start_time
          );
        }
        if (data.campaign_detail.kick_off_end_time) {
          self.kick_off_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_end_time
          );
        }
        if (data.campaign_detail.gold_ball_start_time) {
          self.gold_ball_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_start_time
          );
        }
        if (data.campaign_detail.gold_ball_end_time) {
          self.gold_ball_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_end_time
          );
        }
        if (data.campaign_detail.graduation_start_time) {
          self.graduation_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_start_time
          );
        }
        if (data.campaign_detail.graduation_end_time) {
          self.graduation_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_end_time
          );
        }
        self.campaignType = data.campaign_detail.campaign_type;
        self.loading = false;
      };
      const failureHandler = function (res) {
        const data = res;
        console.log(data);
      };

      let formData = {};
      formData["campaign_id"] = this.campaignID;
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },

    postCallAxios() {
      const self = this;
      const successHandler = function (res) {
        console.log(res.data);
        self.formLoading = false;
        location.reload();
      };
      const failureHandler = function (res) {
        console.log("failure", res);
        self.formLoading = false;
      };
      let form = {
        campaign_type: "school",
        kick_off_date: self.formattedKickOffDate,
        pre_registration_date: self.formattedPreregistrationDate,
        gold_ball_giveaway_date: self.formattedgoldenballDate,
        graduation_celebration_date: self.formattedgraduationDate,
        gold_ball_eblast_date: self.formatedEblastGoldBallDate,
        graduation_eblast_date: self.formatedEblastGraduationDate,
      };
      if (self.pre_registration_start_time) {
        this.pre_registration_start_time = Helper.convertTime12To24(
          self.pre_registration_start_time
        );
      }
      if (self.pre_registration_end_time) {
        this.pre_registration_end_time = Helper.convertTime12To24(
          self.pre_registration_end_time
        );
      }

      if (self.kick_off_start_time) {
        this.kick_off_start_time = Helper.convertTime12To24(
          self.kick_off_start_time
        );
      }
      if (self.kick_off_end_time) {
        this.kick_off_end_time = Helper.convertTime12To24(
          self.kick_off_end_time
        );
      }

      if (self.gold_ball_start_time) {
        this.gold_ball_start_time = Helper.convertTime12To24(
          self.gold_ball_start_time
        );
      }
      if (self.gold_ball_end_time) {
        this.gold_ball_end_time = Helper.convertTime12To24(
          self.gold_ball_end_time
        );
      }

      if (self.graduation_start_time) {
        this.graduation_start_time = Helper.convertTime12To24(
          self.graduation_start_time
        );
      }
      if (self.graduation_end_time) {
        this.graduation_end_time = Helper.convertTime12To24(
          self.graduation_end_time
        );
      }

      if (this.pre_registration_start_time) {
        form.pre_registration_start_time = this.pre_registration_start_time;
      }
      if (this.pre_registration_end_time) {
        form.pre_registration_end_time = this.pre_registration_end_time;
      }

      if (this.kick_off_start_time) {
        form.kick_off_start_time = this.kick_off_start_time;
      }
      if (this.kick_off_end_time) {
        form.kick_off_end_time = this.kick_off_end_time;
      }

      if (this.additional_kickoff_assemblies) {
        form.additional_kickoff_assemblies = this.additional_kickoff_assemblies;
      }
      if (this.gold_ball_start_time) {
        form.gold_ball_start_time = this.gold_ball_start_time;
      }
      if (this.gold_ball_end_time) {
        form.gold_ball_end_time = this.gold_ball_end_time;
      }

      if (this.graduation_start_time) {
        form.graduation_start_time = this.graduation_start_time;
      }
      if (this.graduation_end_time) {
        form.graduation_end_time = this.graduation_end_time;
      }

      if (this.additional_graduation_assemblies) {
        form.additional_graduation_assemblies =
          this.additional_graduation_assemblies;
      }
      if (this.schoolGoal) {
        form.school_default_goal = this.schoolGoal;
      }
      form["campaign_id"] = this.campaignID;
      form["lang"] = this.lang;
      Axios.request_PATCH(
        API_ADMIN_CAMPAIGN_MANAGEMENT_UPDATE,
        form,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    postCallAxiosDistrict() {
      const self = this;
      const successHandler = function (res) {
        console.log(res.data);
        self.formLoading = false;
        location.reload();
      };
      const failureHandler = function (res) {
        console.log("failure", res);
        self.formLoading = false;
      };
      let form = {
        campaign_type: "district",
        kick_off_date: self.formattedKickOffDate,
        pre_registration_date: self.formattedPreregistrationDate,
        gold_ball_giveaway_date: self.formattedgoldenballDate,
        graduation_celebration_date: self.formattedgraduationDate,
        gold_ball_eblast_date: self.formatedEblastGoldBallDate,
        graduation_eblast_date: self.formatedEblastGraduationDate,
      };
      if (self.pre_registration_start_time) {
        this.pre_registration_start_time = Helper.convertTime12To24(
          self.pre_registration_start_time
        );
      }
      if (self.pre_registration_end_time) {
        this.pre_registration_end_time = Helper.convertTime12To24(
          self.pre_registration_end_time
        );
      }

      if (self.kick_off_start_time) {
        this.kick_off_start_time = Helper.convertTime12To24(
          self.kick_off_start_time
        );
      }
      if (self.kick_off_end_time) {
        this.kick_off_end_time = Helper.convertTime12To24(
          self.kick_off_end_time
        );
      }

      if (self.gold_ball_start_time) {
        this.gold_ball_start_time = Helper.convertTime12To24(
          self.gold_ball_start_time
        );
      }
      if (self.gold_ball_end_time) {
        this.gold_ball_end_time = Helper.convertTime12To24(
          self.gold_ball_end_time
        );
      }

      if (self.graduation_start_time) {
        this.graduation_start_time = Helper.convertTime12To24(
          self.graduation_start_time
        );
      }
      if (self.graduation_end_time) {
        this.graduation_end_time = Helper.convertTime12To24(
          self.graduation_end_time
        );
      }

      if (this.pre_registration_start_time) {
        form.pre_registration_start_time = this.pre_registration_start_time;
      }
      if (this.pre_registration_end_time) {
        form.pre_registration_end_time = this.pre_registration_end_time;
      }

      if (this.kick_off_start_time) {
        form.kick_off_start_time = this.kick_off_start_time;
      }
      if (this.kick_off_end_time) {
        form.kick_off_end_time = this.kick_off_end_time;
      }

      if (this.additional_kickoff_assemblies) {
        form.additional_kickoff_assemblies = this.additional_kickoff_assemblies;
      }
      if (this.gold_ball_start_time) {
        form.gold_ball_start_time = this.gold_ball_start_time;
      }
      if (this.gold_ball_end_time) {
        form.gold_ball_end_time = this.gold_ball_end_time;
      }

      if (this.graduation_start_time) {
        form.graduation_start_time = this.graduation_start_time;
      }
      if (this.graduation_end_time) {
        form.graduation_end_time = this.graduation_end_time;
      }

      if (this.additional_graduation_assemblies) {
        form.additional_graduation_assemblies =
          this.additional_graduation_assemblies;
      }
      if (this.schoolGoal) {
        form.district_goal = this.schoolGoal;
      }
      form["campaign_id"] = this.campaignID;
      form["lang"] = this.lang;
      Axios.request_PATCH(
        API_ADMIN_CAMPAIGN_MANAGEMENT_UPDATE,
        form,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    StarterMail() {
      const self = this;
      self.formLoading = true;
      const successHandler = (res) => {
        let data = res.data;
        console.log("success ", data);
        this.showToast({
          message: "Successfully Sent Mail",
          color: "s",
        });
        self.formLoading = false;
      };

      const failureHandler = (res) => {
        this.showToast({
          message: res,
          color: "e",
        });
        self.formLoading = false;
      };

      let dataTableParams = {};
      dataTableParams.school_id = this.selectedCampaignData.organizationData.id;
      Axios.request_GET(
        API_ADMIN_GET_STARTER_KIT_MAIL,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
  },
  mounted() {
    if (this.$route.query.campaign_id) {
      this.campaignID = this.$route.query.campaign_id;
      this.getCampaignDetail();
    }
    this.getSchoolDetails();
    this.getStateList();
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 315%;
  color: #ffffff;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.cardActions {
  position: sticky;
  bottom: 0px;
  width: 500px;
  background-color: white;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}

.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-left: 13px;
}

.file-input-col {
  margin-left: 13px;
}
img.logo {
  margin-left: 20px;
  max-height: 100px;
  max-width: 200px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
@media (min-width: 0px) and (max-width: 600px) {
  .v-card__title {
    width: 100%;
  }
  .cardActions {
    width: 100%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
