<style>
.vue__time-picker-dropdown ul li:not([disabled]).active,
.vue__time-picker-dropdown ul li:not([disabled]).active:focus,
.vue__time-picker-dropdown ul li:not([disabled]).active:hover,
.vue__time-picker .dropdown ul li:not([disabled]).active,
.vue__time-picker .dropdown ul li:not([disabled]).active:focus,
.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #7253cf;
  color: #fff;
}

.vue__time-picker input.display-time {
  border: 1px solid #0000005e;
  height: 2.5em;
  width: 100%;
  padding: 0.3em 0.5em;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
}
.vue__time-picker {
  width: 100%;
}
</style>
<template>
  <div class="px-7 py-6" style="background-color: #e5e5e5">
    <div class="text-center" v-if="loading" style="min-height: 500px">
      <v-progress-circular
        :size="100"
        :width="10"
        style="
          text-align: center;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 200px;
        "
        color="#7253CF"
        indeterminate
        ><v-overlay></v-overlay
      ></v-progress-circular>
    </div>
    <v-card style="padding-left: 13px; padding-right: 13px">
      <div></div>
      <br />

      <!-- <v-card-title height="100px" dark color="#7253cf">
        <div class="d-flex">
          <h3 style=" color: rgb(56, 34, 122);margin-left: 82px;">
            School Info
          </h3>
        </div>
      </v-card-title>-->
      <!-- <v-row no-gutters justify="end">
        <v-col cols="7" class="px-2">
          <h3 style="color: rgb(56, 34, 122)">School Info</h3>
        </v-col>
      </v-row>-->
      <v-row v-if="$vuetify.breakpoint.smAndUp">
        <br />
        <div
          class="welcome-section"
          style="height: 93px; color: rgb(44, 25, 99); margin-top: 2%"
        >
          <span style="margin-left: -65px">
            <img
              src="@/assets/thingsToDo/WizFitLogo11.png"
              height="140px"
              width="140px"
          /></span>
          <span style="margin-right: 22%; font-weight: 500">
            Welcome to the Harlem Wizards WizFit Challenge Dashboard
          </span>
        </div>
        <br />
        <div class="step-heading" style="z-index: 1">
          <br />
          <br />
          <br />
          <div class="stepper-wrapper">
            <div
              class="stepper-item completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(1)
              "
            >
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="50px"
                  width="50px"
                  @click="firsts"
                />
              </div>
              <div
                class="step-name"
                style="font-size: 18px; font-weight: 500; color: #7356e4"
              >
                <span>Confirm Your </span><br /><span>school Info</span>
              </div>
            </div>
            <div class="stepper-item" v-else>
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/confirmnot1.png"
                  height="50px"
                  width="50px"
                  @click="firsts"
                />
              </div>
              <div class="step-name" style="font-size: 18px; font-weight: 500">
                <span>Confirm Your </span><br /><span>school Info</span>
              </div>
            </div>
            <div
              class="stepper-item completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(2)
              "
            >
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="50px"
                  width="50px"
                  @click="seconds"
                />
              </div>
              <div
                class="step-name"
                style="font-size: 18px; font-weight: 500; color: #7356e4"
              >
                Objectives
              </div>
            </div>
            <div class="stepper-item" v-else @click="seconds">
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/objnot1.png"
                  height="50px"
                  width="50px"
                  @click="seconds"
                />
              </div>
              <div class="step-name" style="font-size: 18px; font-weight: 500">
                Objectives
              </div>
            </div>
            <div
              class="stepper-item completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(3)
              "
            >
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="50px"
                  width="50px"
                  @click="thirds"
                />
              </div>
              <div
                class="step-name"
                style="font-size: 18px; font-weight: 500; color: #7356e4"
              >
                Timeline
              </div>
            </div>
            <div class="stepper-item" v-else @click="thirds">
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/timelinenot1.png"
                  height="50px"
                  width="50px"
                  @click="thirds"
                />
              </div>
              <div class="step-name" style="font-size: 18px; font-weight: 500">
                Timeline
              </div>
            </div>
            <div
              class="stepper-item completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(4)
              "
            >
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="50px"
                  width="50px"
                  @click="fourths"
                />
              </div>
              <div
                class="step-name"
                style="font-size: 18px; font-weight: 500; color: #7356e4"
              >
                Incentives
              </div>
            </div>
            <div class="stepper-item" v-else @click="fourths">
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/incentivenot1.png"
                  height="50px"
                  width="50px"
                  @click="fourths"
                />
              </div>
              <div class="step-name" style="font-size: 18px; font-weight: 500">
                Incentives
              </div>
            </div>
            <div
              class="stepper-item completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(5)
              "
            >
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="50px"
                  width="50px"
                  @click="fifths"
                />
              </div>
              <div
                class="step-name"
                style="font-size: 18px; font-weight: 500; color: #7356e4"
              >
                <span>Homeroom </span><br /><span>Teacher List</span>
              </div>
            </div>
            <div class="stepper-item" v-else @click="fifths">
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/homeroomnot1.png"
                  height="50px"
                  width="50px"
                  @click="fifths"
                />
              </div>
              <div class="step-name" style="font-size: 18px; font-weight: 500">
                <span>Homeroom </span><br /><span>Teacher List</span>
              </div>
            </div>
            <div
              class="stepper-item completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(6)
              "
            >
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="50px"
                  width="50px"
                  @click="sixths"
                />
              </div>
              <div
                class="step-name"
                style="font-size: 18px; font-weight: 500; color: #7356e4"
              >
                <span>Fundraiser Chair</span><br /><span>/Promo Video</span>
              </div>
            </div>
            <div class="stepper-item" v-else @click="sixths">
              <div class="step-counter">
                <img
                  src="@/assets/thingsToDo/promonot1.png"
                  height="50px"
                  width="50px"
                  @click="sixths"
                />
              </div>
              <div class="step-name" style="font-size: 18px; font-weight: 500">
                <span>Fundraiser Chair</span><br /><span>/Promo Video</span>
              </div>
            </div>
          </div>
        </div>
        <v-col cols="12" sm="12" md="12">
          <v-card-text>
            <v-form ref="schoolForm" style="margin-top: 40px">
              <!--    <div class="step-accordion" v-if="this.first">
                <span class="accor-left">Step 1</span>
                <div style="position: absolute; left: 22%">
                  <span> <img src="@/assets/thingsToDo/verify.png" /></span
                  >&nbsp;
                  <span>Confirm Your School Info</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag()"
                    v-if="!this.tableFlag"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase()"
                    v-else-if="this.tableFlag"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div v-if="this.tableFlag && this.first" class="step-discription">
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>Contact Person Name</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="contactPersonName"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="6" class="px-2">
                    <label>Contact Person Email</label>
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required, rules.email]"
                      v-model="email"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <label>Contact Person Phone</label>
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.mobile]"
                      v-model="mobile"
                      type="number"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3" justify="center">
                  <v-col cols="6" class="px-2">
                    <label>School Name</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="schoolName"
                      :rules="[rules.required]"
                      class="formFields"
                      color="#7253CF"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <label>School Address</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="school_address"
                      color="#7253CF"
                      :rules="[rules.required]"
                      class="formFields"
                      :disabled="formLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="6" class="px-2">
                    <label>Host Organization</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="leadGroup"
                      color="#7253CF"
                      class="formFields"
                      :rules="[rules.required]"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <label>Total Students</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="totalStudents"
                      color="#7253CF"
                      class="formFields"
                      type="number"
                      :disabled="formLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="6" class="px-2">
                    <label>City</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="city"
                      :rules="[rules.required]"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <label>State</label>
                    <v-autocomplete
                      outlined
                      dense
                      :rules="[rules.required]"
                      v-model="state"
                      :items="statesList"
                      item-text="state_name"
                      item-value="id"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label
                      >Shipping Address (WizFit Promo Kit / WizFit
                      Prizes)</label
                    >
                    <v-textarea
                      outlined
                      dense
                      v-model="shippingAddress"
                      :rules="[rules.required]"
                      color="#7253CF"
                      class="formFields"
                      row-height="8"
                      :disabled="formLoading"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    @click="step1Confirm(), submitSchoolForm()"
                    color="#38227A"
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  >
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-left: 20px;
                    "
                    @click="seconds()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--    <div class="step-accordion-second" v-if="this.second">
                <span class="accor-left1">Step 2</span>
                <div style="position: absolute; left: 22%">
                  <span> <img src="@/assets/thingsToDo/target.png" /></span
                  >&nbsp;
                  <span>Objectives</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag2()"
                    v-if="!this.tableFlag2"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase2()"
                    v-else-if="this.tableFlag2"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div
                v-if="this.tableFlag2 && this.second"
                class="step-discription"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="1" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                      prepend-inner-icon="mdi-currency-usd"
                   disabled >
                    </v-text-field>
                  </v-col>
                  <v-col cols="11" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="top_goal"
                      label="Fundraising Goal (Your Proceeds)"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Please provide a statement describing the purpose for
                        the fundraiser - be brief, but specific (for example, we
                        are raising money to purchase new playground equipment).
                      </span>
                    </label>
                    <v-textarea
                      outlined
                      dense
                      v-model="raising_money_purpose"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters justify="center">
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-right: 20px;
                    "
                    @click="firsts()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step2Confirm(), submitSchoolForm()"
                    style="width: 100px; font-size: 20px"
                    v-if="
                      this.top_goal != '' && this.raising_money_purpose != ''
                    "
                    >Submit</v-btn
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step2Confirm(), submitSchoolForm()"
                    style="width: 100px"
                    v-else
                    disabled
                    >Submit</v-btn
                  >
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-left: 20px;
                    "
                    @click="thirds()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--    <div class="step-accordion" v-if="this.third">
                <span class="accor-left">Step 3</span>
                <div style="position: absolute; left: 22%">
                  <span><img src="@/assets/thingsToDo/timeline.png" /></span
                  >&nbsp;
                  <span>Timeline</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag3()"
                    v-if="!this.tableFlag3"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase3()"
                    v-else-if="this.tableFlag3"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->

              <div
                v-if="this.tableFlag3 && this.third"
                class="step-discription"
              >
                <br />
                <div >
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.pre_registration_date3 }}
                    </v-col>
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.pre_registration_start_time_str }} -
                      {{ this.pre_registration_end_time_str }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                    >
                      ZOOM
                    </v-col>
                    <v-col
                      cols="4"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                    >
                      Pre-Registration
                    </v-col>
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.kick_off_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.kick_off_start_time_str }}-{{
                        this.kick_off_end_time_str
                      }}
                    </v-col>
                    <v-col
                      cols="2"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >IN-PERSON</v-col
                    >
                    <v-col
                      cols="4"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                    >
                      Kick Off Assembly
                      <div>
                        <v-icon
                          @click="settimelineFlag()"
                          v-if="!this.tabletimelineFlag"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 86%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          @click="setFlagtimelineCollpase()"
                          v-else-if="this.tabletimelineFlag"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 86%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-down</v-icon
                        >
                      </div></v-col
                    >
                    <br />
                  </v-row>

                  <div v-if="this.tabletimelineFlag">
                    <label
                      style="margin-left: 1%; font-size: 18px; font-weight: 500"
                      >Additional Assemblies</label
                    >
                    <v-row no-gutters justify="center">
                      <br />
                      <div
                        style="
                          width: 100%;
                          padding: 10px;
                          border: 1px solid gray;
                          border-radius: 5px;
                        "
                        v-html="additional_kickoff_assemblies"
                      ></div>
                    </v-row>
                  </div>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.gold_ball_eblast_date_str_3 }}
                    </v-col>
                    <v-col cols="3" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="2"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >EMAIL BLAST</v-col
                    >
                    <v-col
                      cols="4"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Platinum Ball</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.gold_ball_giveaway_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.gold_ball_start_time_str }} -
                      {{ this.gold_ball_end_time_str }}</v-col
                    >
                    <v-col
                      cols="2"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >ZOOM</v-col
                    >
                    <v-col
                      cols="4"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Platinum Ball Giveway</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.graduation_eblast_date_str_3 }}
                    </v-col>
                    <v-col cols="3" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="2"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >EMAIL BLAST</v-col
                    >
                    <v-col
                      cols="4"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Graduation Celebration</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.graduation_celebration_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.graduation_start_time_str }} -
                      {{ this.graduation_end_time_str }}</v-col
                    >
                    <v-col
                      cols="2"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >IN-PERSON</v-col
                    >
                    <v-col
                      cols="4"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Graduation Celebration Assembly
                      <div>
                        <v-icon
                          @click="settimelineFlag1()"
                          v-if="!this.tabletimelineFlag1"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 94%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          @click="setFlagtimelineCollpase1()"
                          v-else-if="this.tabletimelineFlag1"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 94%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-down</v-icon
                        >
                      </div></v-col
                    >
                    <br />
                  </v-row>

                  <div v-if="this.tabletimelineFlag1">
                    <label
                      style="margin-left: 1%; font-size: 18px; font-weight: 500"
                      >Additional Assemblies</label
                    >
                    <v-row no-gutters justify="left">
                      <br />
                      <div
                        style="
                          width: 100%;
                          padding: 10px;
                          border: 1px solid gray;
                          border-radius: 5px;
                        "
                        v-html="additional_graduation_assemblies"
                      ></div>
                    </v-row>
                    <br />
                  </div>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.end_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="3"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                      >11:59 PM</v-col
                    >
                    <v-col cols="2" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="4"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Donation Close</v-col
                    >
                  </v-row>
                </div>
               
                <hr class="new1" />
                <v-row no-gutters justify="center">
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-right: 20px;
                    "
                    @click="seconds()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step3Confirm(), submitSchoolForm()"
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  >
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-left: 20px;
                    "
                    @click="fourths()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--  <div class="step-accordion-second" v-if="this.fourth">
                <span class="accor-left1">Step 4</span>
                <div style="position: absolute; left: 22%">
                  <span> <img src="@/assets/thingsToDo/giftbox.png" /></span
                  >&nbsp;
                  <span>Incentives</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag4()"
                    v-if="!this.tableFlag4"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase4()"
                    v-else-if="this.tableFlag4"
                    style="
                      cursor: pointer;

                      font-size: 41px;
                      color: white;
                    "
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div
                v-if="this.tableFlag4 && this.fourth"
                class="step-discription"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label style="font-size: 18px; font-weight: 500">
                      The best strategy to incentivize your families is to have
                      a tiered structure for celebrating fundraising progress.
                      Please be specific with your activities, and get creative!
                    </label>
                    <label style="font-size: 18px; font-weight: 500">
                      (e.g., Top Activity - Principal Gets Slimed, Middle
                      Activity - Ice-Cream Party, Low Activity - Pajama Day)
                    </label>
                  </v-col>
                </v-row>
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="1" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                      prepend-inner-icon="mdi-currency-usd"
                   disabled >
                    </v-text-field>
                  </v-col>
                  <v-col cols="5" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="top_goal"
                      label="Top Goal Condition"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="top_goal_purpose"
                      label="Top Celebration Activity"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="1" class="px-2">
                  <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                      prepend-inner-icon="mdi-currency-usd"
                   disabled >
                    </v-text-field>
                  </v-col>
                  <v-col cols="5" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="middle_goal"
                      label="Middle Goal Condition (50% of Goal)"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="middle_goal_purpose"
                      label="Middle Celebration Activity"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="1" class="px-2">
                   <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                      prepend-inner-icon="mdi-currency-usd"
                   disabled >
                    </v-text-field>
                  </v-col>
                  <v-col cols="5" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="low_goal"
                      label="Low Goal Condition (25% of Goal)"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="low_goal_purpose"
                      label="Low Celebration Activity"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Optional Other Incentives and celebrations (e.g., Top
                        Fundraising Students, Top Fundraising Class, Student
                        Registration Milestones)</span
                      >
                    </label>
                    <quillEditor
                      v-model="other_incentive"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <v-row no-gutters justify="center">
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-right: 20px;
                    "
                    @click="thirds()"
                    >mdi-arrow-left</v-icon
                  >

                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step4Confirm(), submitSchoolForm()"
                    style="width: 100px; font-size: 20px"
                    v-if="
                      this.top_goal != '' &&
                      this.top_goal_purpose != '' &&
                      this.middle_goal != '' &&
                      this.middle_goal_purpose != '' &&
                      this.low_goal != '' &&
                      this.low_goal_purpose != '' &&
                      this.other_incentive != ''
                    "
                    >Submit</v-btn
                  >
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-left: 20px;
                    "
                    @click="fifths()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--     <div class="step-accordion" v-if="this.fifth">
                <span class="accor-left">Step 5</span>
                <div style="position: absolute; left: 22%">
                  <span> <img src="@/assets/thingsToDo/instructor.png" /></span
                  >&nbsp;
                  <span>Homeroom Teacher List</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag5()"
                    v-if="!this.tableFlag5"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase5()"
                    v-else-if="this.tableFlag5"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->

              <div
                v-if="this.tableFlag5 && this.fifth"
                class="step-discription"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label
                      style="
                        font-size: 20px;
                        font-weight: 500;
                        font-family: lato;
                      "
                    >
                      <span
                        >Use this template to provide a directory of your
                        teachers for fundraiser tracking by classroom and
                        distribution of prizes to students.
                      </span>
                    </label>
                  </v-col>
                </v-row>
                <br />
                <div class="card-text">
                  <v-row>
                    <span
                      style="
                        margin-right: 13%;
                        margin-left: 14%;
                        font-size: 24px;
                        font-weight: 600;
                      "
                      >Spreadsheet Template</span
                    >
                    <v-btn
                      class="submit-btn"
                      :loading="formLoading"
                      dark
                      color="#38227A"
                      style="margin-left: -11%; font-size: 20px"
                      @click="getReport(62)"
                      >Download</v-btn
                    >
                  </v-row>
                </div>
                <br />
                <br />
                <div style="margin-left: 13.5%">
                  <v-row>
                    <div class="card-text">
                      <h4
                        class="heading"
                        style="
                          margin-left: 11px;
                          font-weight: 600;
                          font-size: 24px;
                        "
                      >
                        Attach Spreadsheet File
                      </h4>
                    </div>
                    <br />
                  </v-row>
                  <v-row>
                    <v-col cols="10" class="px-2">
                      <v-text-field
                        outlined
                        dense
                        v-model="title"
                        label="File Name"
                        color="#7253CF"
                        class="formFields"
                        :disabled="formLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="10">
                      <label style="font-size: medium">Upload File</label>
                      <v-file-input
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        outlined
                        v-model="file"
                        color="#7254CF"
                        dense
                        clearable
                        :disabled="formLoading"
                      >
                      </v-file-input>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </div>
                <v-row no-gutters justify="center">
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-right: 20px;
                    "
                    @click="fourths()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="
                      step5Confirm(), submitSchoolForm(), submitBulkUpload()
                    "
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  >
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-left: 20px;
                    "
                    @click="sixths()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--        <div class="step-accordion-second" v-if="this.sixth">
                <span class="accor-left1">Step 6</span>
                <div style="position: absolute; left: 22%">
                  <span><img src="@/assets/thingsToDo/video-call.png" /></span
                  >&nbsp;
                  <span>Fundraiser Chair / Promo Video</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag6()"
                    v-if="!this.tableFlag6"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase6()"
                    v-else-if="this.tableFlag6"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div
                v-if="this.tableFlag6 && this.sixth"
                class="step-discription"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label
                      style="
                        font-size: 20px;
                        font-weight: 500;
                        font-family: lato;
                      "
                    >
                      <span
                        >The Wizards provide a hype video for your promotional
                        use. You or your principal help create the customized,
                        enthusiastic message by making a video using the script
                        we've provided below. Just record and upload!</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <br />
                <div class="card-text">
                  <v-row>
                    <span
                      style="
                        margin-right: 13%;
                        margin-left: 14%;
                        font-size: 24px;
                        font-weight: 600;
                      "
                      >Video Script</span
                    >
                    <v-btn
                      class="submit-btn"
                      :loading="formLoading"
                      dark
                      color="#38227A"
                      style="margin-left: -11%; font-size: 20px"
                      @click="getReportVideo(145)"
                      >Download</v-btn
                    >
                  </v-row>
                </div>
                <br />
                <br />
                <br />
                <div style="margin-left: 13.5%">
                  <v-row>
                    <div class="card-text">
                      <h4
                        class="heading"
                        style="
                          margin-left: 11px;
                          font-weight: 600;
                          font-size: 24px;
                        "
                      >
                        Attach Video File
                      </h4>
                    </div>
                    <br />
                  </v-row>
                  <v-row>
                    <v-col cols="10" class="px-2">
                      <v-text-field
                        outlined
                        dense
                        v-model="title"
                        label="File Name"
                        color="#7253CF"
                        class="formFields"
                        :disabled="formLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="10">
                      <label style="font-size: medium">Upload File</label>
                      <v-file-input
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        outlined
                        v-model="file"
                        color="#7254CF"
                        dense
                        clearable
                        :disabled="formLoading"
                      >
                      </v-file-input>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </div>
                <v-row no-gutters justify="center">
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-right: 20px;
                    "
                    @click="fifths()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="submitLoading"
                    dark
                    color="#38227A"
                    @click="submitBulkUpload(), step6Confirm()"
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  ><br />
                  <v-icon
                    style="
                      cursor: pointer;
                      font-size: 35px;
                      color: #38227a;
                      margin-left: 20px;
                    "
                    @click="firsts"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <br />
              <br />
              <div
                class="footerStyle"
                style="font-size: 13px; font-style: italic"
              >
                Last updated at {{ this.last_updated }}
              </div>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>

      <!-- for mobile view-->
      <v-row v-if="$vuetify.breakpoint.xsOnly">
        <div
          class="welcome-sectionmob"
          style="height: 55px; color: rgb(44, 25, 99)"
        >
          <span style="margin-left: -11%">
            <img
              src="@/assets/thingsToDo/WizFitLogo11.png"
              height="83px"
              width="83px"
          /></span>
          <span style="margin-right: -2%">
            Welcome to the Harlem Wizards WizFit Challenge Dashboard
          </span>
        </div>
        <br />
        <div class="step-heading" style="margin-left: 26%; z-index: 1">
          <br />
          <div class="stepper-wrappermob">
            <div
              class="stepper-itemmob completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(1)
              "
            >
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="30px"
                  width="30px"
                  @click="firsts"
                />
              </div>
              <div class="step-name" style="font-size: 8px">
                <span>Confirm Your</span><br /><span>School Info</span>
              </div>
            </div>
            <div class="stepper-itemmob" v-else @click="firsts">
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/confirmnot1.png"
                  height="30px"
                  width="30px"
                  @click="firsts"
                />
              </div>
              <div class="step-name" style="font-size: 8px">
                <span>Confirm Your</span><br /><span>School Info</span>
              </div>
            </div>
            <div
              class="stepper-itemmob completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(2)
              "
            >
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="30px"
                  width="30px"
                  @click="seconds"
                />
              </div>
              <div class="step-name" style="font-size: 8px">Objectives</div>
            </div>
            <div class="stepper-itemmob" v-else @click="seconds">
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/objnot1.png"
                  height="30px"
                  width="30px"
                  @click="seconds"
                />
              </div>
              <div class="step-name" style="font-size: 8px">Objectives</div>
            </div>
            <div
              class="stepper-itemmob completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(3)
              "
            >
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="30px"
                  width="30px"
                  @click="thirds"
                />
              </div>
              <div class="step-name" style="font-size: 8px">Timeline</div>
            </div>
            <div class="stepper-itemmob" v-else @click="thirds">
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/timelinenot1.png"
                  height="30px"
                  width="30px"
                  @click="thirds"
                />
              </div>
              <div class="step-name" style="font-size: 8px">Timeline</div>
            </div>
            <div
              class="stepper-item completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(4)
              "
            >
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="30px"
                  width="30px"
                  @click="fourths"
                />
              </div>
              <div class="step-name" style="font-size: 8px">Incentives</div>
            </div>
            <div class="stepper-itemmob" v-else @click="fourths">
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/incentivenot1.png"
                  height="30px"
                  width="30px"
                  @click="fourths"
                />
              </div>
              <div class="step-name" style="font-size: 8px">Incentives</div>
            </div>
            <div
              class="stepper-itemmob completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(5)
              "
            >
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="30px"
                  width="30px"
                  @click="fifths"
                />
              </div>
              <div class="step-name" style="font-size: 8px">
                <span>Homeroom </span><br /><span>Teacher List</span>
              </div>
            </div>
            <div class="stepper-itemmob" v-else @click="fifths">
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/homeroomnot1.png"
                  height="30px"
                  width="30px"
                  @click="fifths"
                />
              </div>
              <div class="step-name" style="font-size: 8px">
                <span>Homeroom </span><br /><span>Teacher List</span>
              </div>
            </div>
            <div
              class="stepper-itemmob completed"
              v-if="
                this.progress_status != null && this.progress_status.includes(6)
              "
            >
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/confirmsrc.png"
                  height="30px"
                  width="30px"
                  @click="sixths"
                />
              </div>
              <div class="step-name" style="font-size: 8px">
                <span>Fundraiser Chair </span><br /><span>/Promo Video</span>
              </div>
            </div>
            <div class="stepper-itemmob" v-else @click="sixths">
              <div class="step-countermob">
                <img
                  src="@/assets/thingsToDo/promonot1.png"
                  height="30px"
                  width="30px"
                  @click="sixths"
                />
              </div>
              <div class="step-name" style="font-size: 8px">
                <span>Fundraiser Chair </span><br /><span>/Promo Video</span>
              </div>
            </div>
          </div>
        </div>
        <v-col cols="12" sm="12" md="12">
          <v-card-text style="padding: 1px">
            <v-form ref="schoolForm" style="margin-top: 40px">
              <!--  <div class="step-accordionmob" v-if="this.first">
                <span class="accor-leftmob">Step&nbsp;1</span>
                <div style="margin-right: 3%">
                  <span>
                    <img
                      src="@/assets/thingsToDo/verify.png"
                      width="20px"
                      height="20px" /></span
                  >&nbsp;
                  <span>Confirm Your School Info</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag()"
                    v-if="!this.tableFlag"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase()"
                    v-else-if="this.tableFlag"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div
                v-if="this.tableFlag && this.first"
                class="step-discriptionmob"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>Contact Person Name</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="contactPersonName"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>Contact Person Email</label>
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.required, rules.email]"
                      v-model="email"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <label>Contact Person Phone</label>
                    <v-text-field
                      outlined
                      dense
                      :rules="[rules.mobile]"
                      v-model="mobile"
                      type="number"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="mt-3" justify="center">
                  <v-col cols="12" class="px-2">
                    <label>School Name</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="schoolName"
                      :rules="[rules.required]"
                      class="formFields"
                      color="#7253CF"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <label>School Address</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="school_address"
                      color="#7253CF"
                      :rules="[rules.required]"
                      class="formFields"
                      :disabled="formLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>Host Organization</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="leadGroup"
                      color="#7253CF"
                      class="formFields"
                      :rules="[rules.required]"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <label>Total Students</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="totalStudents"
                      color="#7253CF"
                      class="formFields"
                      type="number"
                      :disabled="formLoading"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>City</label>
                    <v-text-field
                      outlined
                      dense
                      v-model="city"
                      :rules="[rules.required]"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <label>State</label>
                    <v-autocomplete
                      outlined
                      dense
                      :rules="[rules.required]"
                      v-model="state"
                      :items="statesList"
                      item-text="state_name"
                      item-value="id"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label
                      >Shipping Address (WizFit Promo Kit / WizFit
                      Prizes)</label
                    >
                    <v-textarea
                      outlined
                      dense
                      v-model="shippingAddress"
                      :rules="[rules.required]"
                      color="#7253CF"
                      class="formFields"
                      row-height="8"
                      :disabled="formLoading"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    @click="step1Confirm(), submitSchoolForm()"
                    color="#38227A"
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  >
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="seconds()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--  <div class="step-accordionmob-second" v-if="this.second">
                <span class="accor-leftmob1">Step&nbsp;2</span>
                <div style="margin-right: 24%">
                  <span>
                    <img
                      src="@/assets/thingsToDo/target.png"
                      width="20px"
                      height="20px" /></span
                  >&nbsp;
                  <span>Objectives</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag2()"
                    v-if="!this.tableFlag2"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase2()"
                    v-else-if="this.tableFlag2"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div
                v-if="this.tableFlag2 && this.second"
                class="step-discriptionmob"
              >
                <br />
                <v-row>
                  <v-col cols="3" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                       prepend-inner-icon="mdi-currency-usd"
                       disabled>
                    </v-text-field>
                  </v-col>
                  <v-col cols="9" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="top_goal"
                      label="Fundraising Goal (Your Proceeds)"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Please provide a statement describing the purpose for
                        the fundraiser - be brief, but specific (for example, we
                        are raising money to purchase new playground equipment).
                      </span>
                    </label>
                    <v-textarea
                      outlined
                      dense
                      v-model="raising_money_purpose"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>

                <v-row no-gutters justify="center">
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="firsts()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step2Confirm(), submitSchoolForm()"
                    style="width: 100px; font-size: 20px"
                    v-if="
                      this.top_goal != '' && this.raising_money_purpose != ''
                    "
                    >Submit</v-btn
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step2Confirm(), submitSchoolForm()"
                    style="width: 100px"
                    v-else
                    disabled
                    >Submit</v-btn
                  >
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="thirds()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--   <div class="step-accordionmob" v-if="this.third" >
                <span class="accor-leftmob">Step&nbsp;3</span>
                <div style="margin-right: 27%">
                  <span
                    ><img
                      src="@/assets/thingsToDo/timeline.png"
                      width="20px"
                      height="20px" /></span
                  >&nbsp;
                  <span>Timeline</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag3()"
                    v-if="!this.tableFlag3"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase3()"
                    v-else-if="this.tableFlag3"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->

              <div
                v-if="this.tableFlag3 && this.third"
                class="step-discriptionmob"
              >
                <br />
                <div >
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.pre_registration_date3 }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 15px"
                    >
                      {{ this.pre_registration_start_time_str }} -
                      {{ this.pre_registration_end_time_str }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px; font-weight: 500"
                    >
                      ZOOM
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 19px"
                    >
                      Pre-Registration
                    </v-col>
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.kick_off_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 15px"
                    >
                      {{ this.kick_off_start_time_str }}-{{
                        this.kick_off_end_time_str
                      }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px; font-weight: 500"
                      >IN-PERSON</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 19px"
                    >
                      Kick Off Assembly
                      <div>
                        <v-icon
                          @click="settimelineFlag()"
                          v-if="!this.tabletimelineFlag"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 86%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          @click="setFlagtimelineCollpase()"
                          v-else-if="this.tabletimelineFlag"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 57%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-down</v-icon
                        >
                      </div></v-col
                    >
                    <br />
                  </v-row>

                  <div v-if="this.tabletimelineFlag">
                    <label
                      style="margin-left: 1%; font-size: 18px; font-weight: 500"
                      >Additional Assemblies</label
                    >
                    <v-row no-gutters justify="center">
                      <br />
                      <div
                        style="
                          width: 100%;
                          padding: 10px;
                          border: 1px solid gray;
                          border-radius: 5px;
                        "
                        v-html="additional_kickoff_assemblies"
                      ></div>
                    </v-row>
                  </div>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.gold_ball_eblast_date_str_3 }}
                    </v-col>
                    <v-col cols="3" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px; font-weight: 500"
                      >EMAIL BLAST</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 19px"
                      >Platinum Ball</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.gold_ball_giveaway_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="13"
                      class="px-2 timeline1"
                      style="font-size: 15px"
                    >
                      {{ this.gold_ball_start_time_str }} -
                      {{ this.gold_ball_end_time_str }}</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px; font-weight: 500"
                      >ZOOM</v-col
                    >
                    <v-col
                      cols="14"
                      class="px-2 timeline2"
                      style="font-size: 19px"
                      >Platinum Ball Giveway</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.graduation_eblast_date_str_3 }}
                    </v-col>
                    <v-col cols="3" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px; font-weight: 500"
                      >EMAIL BLAST</v-col
                    >
                    <v-col
                      cols="14"
                      class="px-2 timeline2"
                      style="font-size: 19px"
                      >Graduation Celebration</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.graduation_celebration_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 15px"
                    >
                      {{ this.graduation_start_time_str }} -
                      {{ this.graduation_end_time_str }}</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px; font-weight: 500"
                      >IN-PERSON</v-col
                    >
                    <v-col
                      cols="14"
                      class="px-2 timeline2"
                      style="font-size: 19px"
                      >Graduation Celebration Assembly
                      <div>
                        <v-icon
                          @click="settimelineFlag1()"
                          v-if="!this.tabletimelineFlag1"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 94%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          @click="setFlagtimelineCollpase1()"
                          v-else-if="this.tabletimelineFlag1"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 94%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-down</v-icon
                        >
                      </div></v-col
                    >
                    <br />
                  </v-row>

                  <div v-if="this.tabletimelineFlag1">
                    <label
                      style="margin-left: 1%; font-size: 18px; font-weight: 500"
                      >Additional Assemblies</label
                    >
                    <v-row no-gutters justify="left">
                      <br />
                      <div
                        style="
                          width: 100%;
                          padding: 10px;
                          border: 1px solid gray;
                          border-radius: 5px;
                        "
                        v-html="additional_graduation_assemblies"
                      ></div>
                    </v-row>
                    <br />
                  </div>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.end_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="13"
                      class="px-2 timeline1"
                      style="font-size: 15px"
                      >11:59 PM</v-col
                    >
                    <v-col cols="2" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="14"
                      class="px-2 timeline2"
                      style="font-size: 19px"
                      >Donation Close</v-col
                    >
                  </v-row>
                </div>
              <div v-if="this.campaignType == 'district'">
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.pre_registration_dated }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.preRegistrationTime }} -
                      {{ this.preRegistrationEndTime }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                    >
                      ZOOM
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                    >
                      Pre-Registration
                    </v-col>
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.kickoff_dated }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.kickoffstarttime }}-{{ this.kickoffendtime }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >IN-PERSON</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                    >
                      Kick Off Assembly
                      <div>
                        <v-icon
                          @click="settimelineFlag()"
                          v-if="!this.tabletimelineFlag"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 86%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          @click="setFlagtimelineCollpase()"
                          v-else-if="this.tabletimelineFlag"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 86%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-down</v-icon
                        >
                      </div></v-col
                    >
                    <br />
                  </v-row>

                  <div v-if="this.tabletimelineFlag">
                    <label
                      style="margin-left: 1%; font-size: 18px; font-weight: 500"
                      >Additional Assemblies</label
                    >
                    <v-row no-gutters justify="center">
                      <br />
                      <div
                        style="
                          width: 100%;
                          padding: 10px;
                          border: 1px solid gray;
                          border-radius: 5px;
                        "
                        v-html="additional_kickoff_assemblies"
                      ></div>
                    </v-row>
                  </div>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.gold_ball_eblstd }}
                    </v-col>
                    <v-col cols="3" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >EMAIL BLAST</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Platinum Ball</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.golden_ball_dated }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.goldenballstarttime }} -
                      {{ this.goldenballendtime }}</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >ZOOM</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Platinum Ball Giveway</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.graduation_eblstd }}
                    </v-col>
                    <v-col cols="12" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >EMAIL BLAST</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Graduation Celebration</v-col
                    >
                  </v-row>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.graduation_dated }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                    >
                      {{ this.graduationstarttime }} -
                      {{ this.graduationendtime }}</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 20px; font-weight: 600"
                      >IN-PERSON</v-col
                    >
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Graduation Celebration Assembly
                      <div>
                        <v-icon
                          @click="settimelineFlag1()"
                          v-if="!this.tabletimelineFlag1"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 94%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-up</v-icon
                        >
                        <v-icon
                          @click="setFlagtimelineCollpase1()"
                          v-else-if="this.tabletimelineFlag1"
                          style="
                            cursor: pointer;
                            font-size: 25px;
                            color: black;
                            margin-left: 94%;
                            margin-top: -16%;
                          "
                          >mdi-chevron-down</v-icon
                        >
                      </div></v-col
                    >
                    <br />
                  </v-row>

                  <div v-if="this.tabletimelineFlag1">
                    <label
                      style="margin-left: 1%; font-size: 18px; font-weight: 500"
                      >Additional Assemblies</label
                    >
                    <v-row no-gutters justify="left">
                      <br />
                      <div
                        style="
                          width: 100%;
                          padding: 10px;
                          border: 1px solid gray;
                          border-radius: 5px;
                        "
                        v-html="additional_graduation_assemblies"
                      ></div>
                    </v-row>
                    <br />
                  </div>
                  <hr class="new1" />
                  <v-row no-gutters justify="left">
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-weight: 500; color: #38227a"
                    >
                      {{ this.end_date_str_3 }}
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline1"
                      style="font-size: 16px"
                      >11:59 PM</v-col
                    >
                    <v-col cols="2" class="px-2 timeline1"> </v-col>
                    <v-col
                      cols="12"
                      class="px-2 timeline2"
                      style="font-size: 20px; font-weight: 600"
                      >Donation Close</v-col
                    >
                  </v-row>
                </div>
                <hr class="new1" />
                <v-row no-gutters justify="center">
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="seconds()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step3Confirm(), submitSchoolForm()"
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  >
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="fourths()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--  <div class="step-accordionmob-second" v-if="this.fourth">
                <span class="accor-leftmob1">Step&nbsp;4</span>
                <div style="margin-right: 24%">
                  <span>
                    <img
                      src="@/assets/thingsToDo/giftbox.png"
                      width="20px"
                      height="20px" /></span
                  >&nbsp;
                  <span>Incentives</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag4()"
                    v-if="!this.tableFlag4"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase4()"
                    v-else-if="this.tableFlag4"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div
                v-if="this.tableFlag4 && this.fourth"
                class="step-discriptionmob"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label style="font-size: 18px; font-weight: 500">
                      The best strategy to incentivize your families is to have
                      a tiered structure for celebrating fundraising progress.
                      Please be specific with your activities, and get creative!
                    </label>
                    <label style="font-size: 18px; font-weight: 500">
                      (e.g., Top Activity - Principal Gets Slimed, Middle
                      Activity - Ice-Cream Party, Low Activity - Pajama Day)
                    </label>
                  </v-col>
                </v-row>
                <br />
                <v-row>
                  <v-col cols="3" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                      disabled
                    >
                      <template v-slot:label>
                        <span
                          style="
                            color: #2c1963;
                            font-size: 16px;
                            top: 2px;
                            position: relative;
                            margin-left: -2px;
                          "
                          >$</span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="9" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="top_goal"
                      label="Top Goal Condition"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="top_goal_purpose"
                      label="Top Celebration Activity"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                      disabled
                    >
                      <template v-slot:label>
                        <span
                          style="
                            color: #2c1963;
                            font-size: 16px;
                            top: 2px;
                            position: relative;
                            margin-left: -2px;
                          "
                          >$</span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="9" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="middle_goal"
                      label="Middle Goal Condition (50% of Goal)"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="middle_goal_purpose"
                      label="Middle Celebration Activity"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      color="#7253CF"
                      class="formFields"
                      disabled
                    >
                      <template v-slot:label>
                        <span
                          style="
                            color: #2c1963;
                            font-size: 16px;
                            top: 2px;
                            position: relative;
                            margin-left: -2px;
                          "
                          >$</span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="9" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="low_goal"
                      label="Low Goal Condition (25% of Goal)"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="px-2">
                    <v-text-field
                      outlined
                      dense
                      v-model="low_goal_purpose"
                      label="Low Celebration Activity"
                      color="#7253CF"
                      class="formFields"
                      :disabled="formLoading"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label>
                      <span
                        >Optional Other Incentives and celebrations (e.g., Top
                        Fundraising Students, Top Fundraising Class, Student
                        Registration Milestones)</span
                      >
                    </label>
                    <quillEditor
                      v-model="other_incentive"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
                <br />
                <v-row no-gutters justify="center">
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="thirds()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="step4Confirm(), submitSchoolForm()"
                    style="width: 100px; font-size: 20px"
                    v-if="
                      this.top_goal != '' &&
                      this.top_goal_purpose != '' &&
                      this.middle_goal != '' &&
                      this.middle_goal_purpose != '' &&
                      this.low_goal != '' &&
                      this.low_goal_purpose != '' &&
                      this.other_incentive != ''
                    "
                    >Submit</v-btn
                  >
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="fifths()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--   <div class="step-accordionmob" v-if="this.fifth">
                <span class="accor-leftmob">Step&nbsp;5</span>
                <div style="margin-right: 1%">
                  <span>
                    <img
                      src="@/assets/thingsToDo/instructor.png"
                      width="20px"
                      height="20px" /></span
                  >&nbsp;
                  <span>Homeroom Teacher List</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag5()"
                    v-if="!this.tableFlag5"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase5()"
                    v-else-if="this.tableFlag5"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->

              <div
                v-if="this.tableFlag5 && this.fifth"
                class="step-discriptionmob"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label
                      style="
                        font-size: 20px;
                        font-weight: 500;
                        font-family: lato;
                      "
                    >
                      <span
                        >Use this template to provide a directory of your
                        teachers for fundraiser tracking by classroom and
                        distribution of prizes to students.
                      </span>
                    </label>
                  </v-col>
                </v-row>
                <br />
                <div class="card-text">
                  <v-row>
                    <span
                      style="
                        margin-right: 13%;
                        margin-left: 14%;
                        font-size: 24px;
                        font-weight: 600;
                      "
                      >Spreadsheet Template</span
                    >
                    <v-btn
                      class="submit-btn"
                      :loading="formLoading"
                      dark
                      color="#38227A"
                      style="margin-left: 12%; font-size: 20px"
                      @click="getReport(62)"
                      >Download</v-btn
                    >
                  </v-row>
                </div>
                <br />
                <br />
                <div style="margin-left: 13.5%">
                  <v-row>
                    <div class="card-text">
                      <h4
                        class="heading"
                        style="
                          margin-left: 11px;
                          font-weight: 600;
                          font-size: 24px;
                        "
                      >
                        Attach Spreadsheet File
                      </h4>
                    </div>
                    <br />
                  </v-row>
                  <v-row>
                    <v-col cols="10" class="px-2">
                      <v-text-field
                        outlined
                        dense
                        v-model="title"
                        label="File Name"
                        color="#7253CF"
                        class="formFields"
                        :disabled="formLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="10">
                      <label style="font-size: medium">Upload File</label>
                      <v-file-input
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        outlined
                        v-model="file"
                        color="#7254CF"
                        dense
                        clearable
                        :disabled="formLoading"
                      >
                      </v-file-input>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </div>
                <v-row no-gutters justify="center">
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="fourths()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="formLoading"
                    dark
                    color="#38227A"
                    @click="
                      step5Confirm(), submitSchoolForm(), submitBulkUpload()
                    "
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  >
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="sixths()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <!--  <div class="step-accordionmob-second" v-if="this.sixth">
                <span class="accor-leftmob1">Step&nbsp;6</span>
                <div style="margin-right: -4%">
                  <span
                    ><img
                      src="@/assets/thingsToDo/video-call.png"
                      width="20px"
                      height="20px" /></span
                  >&nbsp;
                  <span>Fundraiser Chair / Promo Video</span>
                </div>
                <div>
                  <v-icon
                    @click="setFlag6()"
                    v-if="!this.tableFlag6"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-up</v-icon
                  >
                  <v-icon
                    @click="setFlagCollpase6()"
                    v-else-if="this.tableFlag6"
                    style="cursor: pointer; font-size: 41px; color: white"
                    >mdi-chevron-down</v-icon
                  >
                </div>
              </div>-->
              <div
                v-if="this.tableFlag6 && this.sixth"
                class="step-discriptionmob"
              >
                <br />
                <v-row no-gutters justify="center">
                  <v-col cols="12" class="px-2">
                    <label
                      style="
                        font-size: 20px;
                        font-weight: 500;
                        font-family: lato;
                      "
                    >
                      <span
                        >The Wizards provide a hype video for your promotional
                        use. You or your principal help create the customized,
                        enthusiastic message by making a video using the script
                        we've provided below. Just record and upload!</span
                      >
                    </label>
                  </v-col>
                </v-row>
                <br />
                <div class="card-text">
                  <v-row>
                    <span
                      style="
                        margin-right: 13%;
                        margin-left: 14%;
                        font-size: 24px;
                        font-weight: 600;
                      "
                      >Video Script</span
                    >
                    <v-btn
                      class="submit-btn"
                      :loading="formLoading"
                      dark
                      color="#38227A"
                      style="margin-left: 12%; font-size: 20px"
                      @click="getReportVideo(145)"
                      >Download</v-btn
                    >
                  </v-row>
                </div>
                <br />
                <br />
                <br />
                <div style="margin-left: 13.5%">
                  <v-row>
                    <div class="card-text">
                      <h4
                        class="heading"
                        style="
                          margin-left: 11px;
                          font-weight: 600;
                          font-size: 24px;
                        "
                      >
                        Attach Video File
                      </h4>
                    </div>
                    <br />
                  </v-row>
                  <v-row>
                    <v-col cols="10" class="px-2">
                      <v-text-field
                        outlined
                        dense
                        v-model="title"
                        label="File Name"
                        color="#7253CF"
                        class="formFields"
                        :disabled="formLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="10">
                      <label style="font-size: medium">Upload File</label>
                      <v-file-input
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        outlined
                        v-model="file"
                        color="#7254CF"
                        dense
                        clearable
                        :disabled="formLoading"
                      >
                      </v-file-input>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>
                </div>
                <v-row no-gutters justify="center">
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="fifths()"
                    >mdi-arrow-left</v-icon
                  >
                  <v-btn
                    class="submit-btn"
                    :loading="submitLoading"
                    dark
                    color="#38227A"
                    @click="submitBulkUpload(), step6Confirm()"
                    style="width: 100px; font-size: 20px"
                    >Submit</v-btn
                  >
                  <v-icon
                    style="cursor: pointer; font-size: 35px; color: #38227a"
                    @click="firsts()"
                    >mdi-arrow-right</v-icon
                  >
                  <br />
                </v-row>
              </div>
              <br />
              <br />
              <div
                class="footerStyle"
                style="font-size: 13px; font-style: italic"
              >
                Last updated at {{ this.last_updated }}
              </div>
            </v-form>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";
import { SALES_REP } from "@/constants/ModuleKeys";
import moment from "moment";
import Helper from "@/helper";
import RULES from "@/common/fieldRules";
import {
  API_MASTER_GET_STATES_LIST,
  API_USER_GET_SALES_REP_LIST,
  API_ADMIN_UPDATE_SCHOOL,
  API_ADMIN_GET_SCHOOL_DETAIL,
  API_USER_GET_DISTRICT_LIST,
  API_MASTER_GET_MESSAGE,
  API_RESOURCE_POST,
  API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
  API_RESOURCE_DOWNLOAD,
} from "@/constants/APIUrls";
import Axios from "@/api/BaseAxios";
export default {
  name: "AddEditViewSchoolModal",
  components: { quillEditor },
  props: ["DistrictFormData"],
  data() {
    return {
      adminAccess: localStorage.getItem("adminAccess"),
      rules: RULES,
      loading: false,
      formLoading: false,
      submitLoading: false,
      previewImage: null,
      LogoImgFile: {},
      schoolName: "",
      school_address: "",
      salesRep: null,
      salesRepList: [],
      city: "",
      state: null,
      statesList: [],
      contactPersonName: "",
      role: "",
      email: "",
      mobile: null,
      shippingAddress: "",
      leadGroup: "",
      district: -1,
      totalStudents: null,
      districtList: [],
      SpecialMessage: "",
      short_school_name: "",
      totalcharacter: 0,
      maxchar: 20,
      menu: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      low_goal: "",
      low_goal_purpose: "",
      top_goal: "",
      top_goal_purpose: "",
      middle_goal: "",
      middle_goal_purpose: "",
      pre_registration_date: "",
      pre_registration_date3: "",
      pre_registration_start_time: "",
      pre_registration_end_time: "",
      pre_registration_start_time_str: "",
      gold_ball_start_time_str: "",
      gold_ball_end_time_str: "",
      pre_registration_end_time_str: "",
      kick_off_start_time_str: "",
      kick_off_end_time_str: "",
      kick_off_date_str_3: "",
      kickoff_date: "",
      kickoff_starttime: "",
      kickoff_endtime: "",
      golden_ball_date: "",
      gold_ball_eblast_date_str_3: "",
      gold_ball_giveaway_date_str_3: "",
      golden_ball_start_time: "",
      golden_ball_end_time: "",
      graduation_eblast_date_str_3: "",
      graduation_celebration_date_str_3: "",
      graduation_start_time_str: "",
      graduation_end_time_str: "",
      end_date_str_3: "",
      graduation_starttime: "",
      graduation_endtime: "",
      graduation_date: "",
      raising_money_purpose: "",
      principal_video: false,
      teacher_wizfit_challenge: false,
      teacher_training_date: "",
      teacher_training_time: "",
      teacher_list: false,
      video_episodes_plan: "",
      mark_videos_watched: false,
      arrange_sponsorship: false,
      notes: "",
      other_incentive: "",
      kickoff_mail: false,
      goldball_mail: false,
      graduation_mail: false,
      kickoff_video: "",
      goldball_video: "",
      graduation_video: "",
      last_updated: "",
      additional_kickoff: "",
      additional_graduation: "",
      tableFlag: true,
      tableFlag2: false,
      tableFlag3: false,
      tableFlag4: false,
      tableFlag5: false,
      tableFlag6: false,
      tableFlag7: false,
      file: {},
      note: "",
      title: "",
      user_id: localStorage.getItem("user_id"),
      tabletimelineFlag: false,
      tabletimelineFlag1: false,
      step1Flag: false,
      step2Flag: false,
      step3Flag: false,
      step4Flag: false,
      step5Flag: false,
      step6Flag: false,
      progress_status: [],
      progress_status1: [],
      progressStatus2: "",
      campaignType: "",
      pre_registration_dated: "",
      kickoff_dated: "",
      golden_ball_dated: "",
      graduation_dated: "",
      gold_ball_eblstd: "",
      graduation_eblstd: "",
      preRegistrationTime: "",
      preRegistrationEndTime: "",
      kickoffstarttime: "",
      kickoffendtime: "",
      goldenballstarttime: "",
      goldenballendtime: "",
      graduationstarttime: "",
      graduationendtime: "",
      first: true,
      second: false,
      third: false,
      fourth: false,
      fifth: false,
      sixth: false,
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "schoolManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewSchoolModal({ show: value });
      },
    },
    type() {
      return this.$store.state.schoolManagement.addEditViewModal.type;
    },
    districtID() {
      return this.$store.state.schoolManagement.addEditViewModal.districtID;
    },
    toastMessage() {
      return "School Info Added";
    },
    dialogTitle() {
      return "Add new School";
    },
    formattedPreregistrationDate: {
      get() {
        if (this.pre_registration_date) {
          return moment(this.pre_registration_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedKickOffDate: {
      get() {
        if (this.kickoff_date) {
          return moment(this.kickoff_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgoldenballDate: {
      get() {
        if (this.golden_ball_date) {
          return moment(this.golden_ball_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedgraduationDate: {
      get() {
        if (this.graduation_date) {
          return moment(this.graduation_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
    formattedTrainingDate: {
      get() {
        if (this.teacher_training_date) {
          return moment(this.teacher_training_date).format("MM-DD-YYYY");
        } else {
          return "";
        }
      },
      set() {
        return "";
      },
    },
  },

  methods: {
    ...mapActions({
      toggleAddEditViewSchoolModal: "schoolManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    onDistrictChangeHandler(value) {
      var selectedDistrict = this.districtList.filter(
        (district) => district.id === value
      )[0];
      this.city = selectedDistrict.city_name;
      this.state = selectedDistrict.state_id;
    },
    /**
     * GET State List
     */
    getStateList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.state_list);
        self.statesList = data.state_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getSalesRepList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_MASTER_GET_STATES_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    /**
     * GET Sales-Rep List
     */
    getSalesRepList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.sales_rep_list);
        self.salesRepList = data.sales_rep_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.getDistrictList();
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_SALES_REP_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },

    /**
     * GET District List
     */
    getDistrictList() {
      const self = this;

      const successHandler = function (res) {
        const data = res.data;
        // console.log(data.district_list);
        self.districtList = data.district_list;
      };

      const failureHandler = function (res) {
        const data = res.data;
        console.log(data);
      };

      const finallyHandler = function () {
        self.setFormDataParams();
        self.getSpecialMessage();
      };

      const formJson = {};

      return Axios.request_GET(
        API_USER_GET_DISTRICT_LIST,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getSpecialMessage() {
      const successHandler = (res) => {
        // console.log(res,"special message")
        this.SpecialMessage = res.data.special_message_list[0].message;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["message_for"] = "school";
      formData["message_type"] = "special_message";
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getSchoolDetails() {
      const self = this;
      const successHandler = (res) => {
        let data = res.data;
        localStorage.setItem("schoolID", data.school_detail.id);
        this.progressStatus2 = data.school_detail.progress;
        localStorage.setItem("progressValue2", this.progressStatus2);
        this.schoolName = data.school_detail.school_name;
        this.school_address = data.school_detail.school_address;
        this.city = data.school_detail.city_name;
        this.contactPersonName = data.school_detail.user_name;
        this.email = data.school_detail.user_email;
        this.mobile = data.school_detail.user_phone;
        this.state = data.school_detail.state;
        this.previewImage = data.school_detail.logo_url;
        this.shippingAddress = data.school_detail.shipping_address;
        this.leadGroup = data.school_detail.lead_group;
        this.district = data.school_detail.district;
        this.totalStudents = data.school_detail.total_student;
        this.pre_registration_date = data.school_detail.pre_registration_date;
        this.pre_registration_dated =
          data.school_detail.pre_registration_date_str_1;
        this.golden_ball_date = data.school_detail.golden_ball_date;
        this.golden_ball_dated = data.school_detail.golden_ball_date_str_1;
        this.kickoff_date = data.school_detail.kickoff_date;
        this.kickoff_dated = data.school_detail.kickoff_date_str_1;
        this.graduation_date = data.school_detail.graduation_date;
        this.graduation_dated = data.school_detail.graduation_date_str_1;
        this.kickoff_mail = data.school_detail.kickoff_mail;
        this.goldball_mail = data.school_detail.goldball_mail;
        this.graduation_mail = data.school_detail.graduation_mail;
        this.progress_status = data.school_detail.progress_status;
        this.gold_ball_eblstd = data.school_detail.gold_ball_eblast_str_1;
        this.graduation_eblstd = data.school_detail.graduation_eblast_str_1;

        if (data.school_detail.kickoff_video) {
          this.kickoff_video = data.school_detail.kickoff_video;
        }
        if (data.school_detail.goldball_video) {
          this.goldball_video = data.school_detail.goldball_video;
        }
        if (data.school_detail.graduation_video) {
          this.graduation_video = data.school_detail.graduation_video;
        }
        if (data.school_detail.raising_money_purpose) {
          this.raising_money_purpose = data.school_detail.raising_money_purpose;
        }
        if (data.school_detail.low_goal) {
          this.low_goal = data.school_detail.low_goal;
        }
        if (data.school_detail.low_goal_purpose) {
          this.low_goal_purpose = data.school_detail.low_goal_purpose;
        }
        if (data.school_detail.middle_goal) {
          this.middle_goal = data.school_detail.middle_goal;
        }
        if (data.school_detail.middle_goal_purpose) {
          this.middle_goal_purpose = data.school_detail.middle_goal_purpose;
        }
        if (data.school_detail.top_goal) {
          this.top_goal = data.school_detail.top_goal;
        }
        if (data.school_detail.top_goal_purpose) {
          this.top_goal_purpose = data.school_detail.top_goal_purpose;
        }
        this.principal_video = data.school_detail.principal_video;
        if (data.school_detail.notes) {
          this.notes = data.school_detail.notes;
        }
        if (data.school_detail.other_incentive) {
          this.other_incentive = data.school_detail.other_incentive;
        }
        this.teacher_wizfit_challenge =
          data.school_detail.teacher_wizfit_challenge;
        this.teacher_training_date = data.school_detail.teacher_training_date;
        this.teacher_list = data.school_detail.teacher_list;
        //  this.video_episodes_plan = data.school_detail.video_episodes_plan;
        this.mark_videos_watched = data.school_detail.mark_videos_watched;

        this.arrange_sponsorship = data.school_detail.arrange_sponsorship;
        this.preRegistrationTime =
          data.school_detail.pre_registration_start_time_str;
        this.preRegistrationEndTime =
          data.school_detail.pre_registration_end_time_str;
        this.kickoffstarttime = data.school_detail.kickoff_starttime_str;
        this.kickoffendtime = data.school_detail.kickoff_endtime_str;
        this.goldenballstarttime =
          data.school_detail.golden_ball_start_time_str;
        this.goldenballendtime = data.school_detail.golden_ball_end_time_str;
        this.graduationstarttime = data.school_detail.graduation_starttime_str;
        this.graduationendtime = data.school_detail.graduation_endtime_str;
        if (data.school_detail.pre_registration_start_time) {
          this.pre_registration_start_time = Helper.convertFrom24To12Format(
            data.school_detail.pre_registration_start_time
          );
        }
        if (data.school_detail.pre_registration_end_time) {
          this.pre_registration_end_time = Helper.convertFrom24To12Format(
            data.school_detail.pre_registration_end_time
          );
        }
        if (data.school_detail.teacher_training_time) {
          this.teacher_training_time = Helper.convertFrom24To12Format(
            data.school_detail.teacher_training_time
          );
        }
        if (data.school_detail.golden_ball_start_time) {
          this.golden_ball_start_time = Helper.convertFrom24To12Format(
            data.school_detail.golden_ball_start_time
          );
        }
        if (data.school_detail.golden_ball_end_time) {
          this.golden_ball_end_time = Helper.convertFrom24To12Format(
            data.school_detail.golden_ball_end_time
          );
        }
        if (data.school_detail.graduation_starttime) {
          this.graduation_starttime = Helper.convertFrom24To12Format(
            data.school_detail.graduation_starttime
          );
        }
        if (data.school_detail.graduation_endtime) {
          this.graduation_endtime = Helper.convertFrom24To12Format(
            data.school_detail.graduation_endtime
          );
        }
        if (data.school_detail.kickoff_starttime) {
          this.kickoff_starttime = Helper.convertFrom24To12Format(
            data.school_detail.kickoff_starttime
          );
        }
        if (data.school_detail.kickoff_endtime) {
          this.kickoff_endtime = Helper.convertFrom24To12Format(
            data.school_detail.kickoff_endtime
          );
        }
        if (data.school_detail.additional_kickoff) {
          this.additional_kickoff = data.school_detail.additional_kickoff;
        }
        if (data.school_detail.additional_graduation) {
          this.additional_graduation = data.school_detail.additional_graduation;
        }
        if (data.school_detail.last_updated) {
          this.last_updated = data.school_detail.last_updated;
        }
        self.loading = false;
      };

      const failureHandler = (res) => {
        console.log("Failure ", res);
        self.loading = false;
      };

      let formData = {};
      formData["school_id"] = this.$route.query.school;
      console.log({ formData });

      /**
       * API Call for GET school detail
       */
      Axios.request_GET(
        API_ADMIN_GET_SCHOOL_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    removeDuplicates(array) {
      return array.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    },
    submitSchoolForm() {
      if (this.$refs.schoolForm.validate()) {
        const self = this;
        self.formLoading = true;
        const successHandler = (res) => {
          let data = res.data;
          console.log("success ", data);
          self.formLoading = false;
          location.reload();
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };
        const failureHandler = (res) => {
          this.showToast({
            message: res,
            color: "e",
          });
          self.formLoading = false;
        };

        let formData = new FormData();
        formData.append(
          "school_id",
          this.selectedCampaignData.organizationData.id
        );
        formData.append("school_name", this.schoolName);
        formData.append("school_address", this.school_address);
        formData.append("user_name", this.contactPersonName);
        formData.append("user_email", this.email);
        if (this.mobile) formData.append("user_phone", this.mobile);
        formData.append("city_name", this.city);
        formData.append("state_id", this.state);
        formData.append("lead_group", this.leadGroup);
        formData.append("shipping_address", this.shippingAddress);
        formData.append("total_student", this.totalStudents);
        if (this.low_goal) {
          formData.append("low_goal", this.low_goal);
        }
        if (this.low_goal_purpose) {
          formData.append("low_goal_purpose", this.low_goal_purpose);
        }
        if (this.top_goal) {
          formData.append("top_goal", this.top_goal);
        }
        if (this.top_goal_purpose) {
          formData.append("top_goal_purpose", this.top_goal_purpose);
        }
        if (this.middle_goal) {
          formData.append("middle_goal", this.middle_goal);
        }
        if (this.middle_goal_purpose) {
          formData.append("middle_goal_purpose", this.middle_goal_purpose);
        }
        if (this.raising_money_purpose) {
          formData.append("raising_money_purpose", this.raising_money_purpose);
        }
        // formData.append("principal_video", this.principal_video);
        if (this.step5Flag) {
          formData.append("teacher_list", true);
        }
        if (this.step6Flag) {
          formData.append("principal_video", true);
        }
        formData.append("notes", this.notes);
        if (this.other_incentive) {
          formData.append("other_incentive", this.other_incentive);
        }
        formData.append(
          "teacher_wizfit_challenge",
          this.teacher_wizfit_challenge
        );

        // formData.append("teacher_list", this.teacher_list);
        formData.append("mark_videos_watched", this.mark_videos_watched);
        formData.append("arrange_sponsorship", this.arrange_sponsorship);
        if (this.kickoff_video) {
          formData.append("kickoff_video", this.kickoff_video);
        }
        if (this.goldball_video) {
          formData.append("goldball_video", this.goldball_video);
        }
        if (this.graduation_video) {
          formData.append("graduation_video", this.graduation_video);
        }
        if (this.formattedTrainingDate) {
          formData.append("teacher_training_date", this.formattedTrainingDate);
        }
        if (this.teacher_training_time) {
          formData.append(
            "teacher_training_time",
            Helper.convertTime12To24(this.teacher_training_time)
          );
        }
        if (this.additional_kickoff) {
          formData.append("additional_kickoff", this.additional_kickoff);
        }
        if (this.additional_graduation) {
          formData.append("additional_graduation", this.additional_graduation);
        }
        this.progress_status1 = [];
        if (this.step1Flag) {
          this.progress_status1.push(1);
        }
        if (this.step2Flag) {
          this.progress_status1.push(2);
        }
        if (this.step3Flag) {
          this.progress_status1.push(3);
        }
        if (this.step4Flag) {
          this.progress_status1.push(4);
        }
        if (this.step5Flag) {
          this.progress_status1.push(5);
        }
        if (this.step6Flag) {
          this.progress_status1.push(6);
        }
        if (this.progress_status1 != null) {
          let progress_status2 = this.removeDuplicates(this.progress_status1);
          this.progress_status = progress_status2.concat(this.progress_status);
          formData.append("progress_status", this.progress_status);
        }
        Axios.request_PATCH(
          API_ADMIN_UPDATE_SCHOOL,
          formData,
          {},
          successHandler,
          failureHandler,
          false
        );
      }
    },
    setFormDataParams() {
      if (this.$route.params["id"]) {
        console.log(this.$route.params["id"]);
        this.district = this.$route.params["id"];
        var selectedDistrict = this.districtList.filter(
          (district) => district.id === this.district
        )[0];
        this.city = selectedDistrict.city_name;
        this.state = selectedDistrict.state_id;
        this.salesRep = this.$route.params[SALES_REP];
        // console.log("in toggle");
      }
    },
    charCount: function () {
      if (this.short_school_name.length > this.maxcharacter) {
        alert("max char limit is 30 character");
        return "";
      } else {
        this.totalcharacter = this.short_school_name.length;
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    setFlag() {
      this.tableFlag = true;
    },
    setFlagCollpase() {
      this.tableFlag = false;
    },
    setFlag2() {
      this.tableFlag2 = true;
    },
    setFlagCollpase2() {
      this.tableFlag2 = false;
    },
    setFlag3() {
      this.tableFlag3 = true;
    },
    setFlagCollpase3() {
      this.tableFlag3 = false;
    },
    setFlag4() {
      this.tableFlag4 = true;
    },
    setFlagCollpase4() {
      this.tableFlag4 = false;
    },
    setFlag5() {
      this.tableFlag5 = true;
    },
    setFlagCollpase5() {
      this.tableFlag5 = false;
    },
    setFlag6() {
      this.tableFlag6 = true;
    },
    setFlagCollpase6() {
      this.tableFlag6 = false;
    },
    setFlag7() {
      this.tableFlag7 = true;
    },
    setFlagCollpase7() {
      this.tableFlag7 = false;
    },
    settimelineFlag() {
      this.tabletimelineFlag = true;
    },
    setFlagtimelineCollpase() {
      this.tabletimelineFlag = false;
    },
    settimelineFlag1() {
      this.tabletimelineFlag1 = true;
    },
    setFlagtimelineCollpase1() {
      this.tabletimelineFlag1 = false;
    },
    step1Confirm() {
      this.step1Flag = true;
    },
    step2Confirm() {
      this.step2Flag = true;
    },
    step3Confirm() {
      this.step3Flag = true;
    },
    step4Confirm() {
      this.step4Flag = true;
    },
    step5Confirm() {
      this.step5Flag = true;
    },
    step6Confirm() {
      this.step6Flag = true;
    },
    getReport(id) {
      const self = this;
      self.btnLoading = true;
      this.indexClicked = id;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.btnLoading = false;
      };
      let formData = {};
      formData["id"] = 62;
      Axios.request_GET_BLOB(
        API_RESOURCE_DOWNLOAD,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    getReportVideo(id) {
      const self = this;
      self.btnLoading = true;
      this.indexClicked = id;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.btnLoading = false;
      };
      let formData = {};
      formData["id"] = 145;
      Axios.request_GET_BLOB(
        API_RESOURCE_DOWNLOAD,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    downloadFile() {
      // Your file content, for example, a JSON object
      const fileContent = { key: "value" };

      // Convert the content to a Blob
      const blob = new Blob([JSON.stringify(fileContent)], {
        type: "application/json",
      });

      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      // Set the file name
      const fileName =
        "https://harlemwizardsinabox-prod-bucket.s3.us-east-1.amazonaws.com/media/school/TEACHER_LIST.xlsx";
      link.download = fileName;

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    },
    downloadFileVideo() {
      // Your file content, for example, a JSON object
      const fileContent = { key: "value" };

      // Convert the content to a Blob
      const blob = new Blob([JSON.stringify(fileContent)], {
        type: "application/json",
      });

      // Create a download link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);

      // Set the file name
      const fileName =
        "https://harlemwizardsinabox-prod-bucket.s3.us-east-1.amazonaws.com/media/school/PTA-Principal_Video_Script_3AE0fom.pdf";
      link.download = fileName;

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    },
    submitBulkUpload() {
      const self = this;
      this.submitLoading = true;
      const successHandler = (res) => {
        self.formLoading = false;
        console.log(res);
        this.showToast({
          message: "File uploaded successfully.",

          color: "s",
        });
        this.file = {};
        this.submitLoading = false;
        this.submitSchoolForm();
      };
      const failureHandler = (res) => {
        this.submitLoading = false;
        console.log(res.data);
        this.showToast({
          message: "something went wrong",
          color: "e",
        });
      };
      let formData = new FormData();
      if (this.file instanceof File) {
        formData.append("document", this.file);
        formData.append("notes", this.note);
        formData.append("title", this.title);
        formData.append("user", this.user_id);
        formData.append(
          "school",
          this.selectedCampaignData.organizationData.id
        );
        //  formData.append("category", this.category);
        console.log("in school");
        {
          Axios.request_POST(
            API_RESOURCE_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
    getCampaignDetail() {
      const self = this;
      const successHandler = function (res) {
        const data = res.data;
        // console.log(data);
        self.kickoff_date = data.campaign_detail.kick_off_date;
        self.graduation_date = data.campaign_detail.graduation_eblast_date;
        self.golden_ball_date = data.campaign_detail.gold_ball_eblast_date;
        self.gold_ball_eblast_date_str_3 =
          data.campaign_detail.gold_ball_eblast_date_str_3;
        self.graduation_eblast_date_str_3 =
          data.campaign_detail.graduation_eblast_date_str_3;
        self.end_date_str_3 = data.campaign_detail.end_date_str_3;
        self.graduation_celebration_date_str_3 =
          data.campaign_detail.graduation_celebration_date_str_3;
        self.pre_registration_date = data.campaign_detail.pre_registration_date;
        self.pre_registration_date3 =
          data.campaign_detail.pre_registration_date_str_3;
        self.kick_off_date_str_3 = data.campaign_detail.kick_off_date_str_3;
        self.gold_ball_giveaway_date_str_3 =
          data.campaign_detail.gold_ball_giveaway_date_str_3;
        self.schoolGoal = data.campaign_detail.school_default_goal;
        self.additional_kickoff_assemblies =
          data.campaign_detail.additional_kickoff_assemblies;
        self.additional_graduation_assemblies =
          data.campaign_detail.additional_graduation_assemblies;
        self.notes = data.campaign_detail.notes;
        if (data.campaign_detail.pre_registration_start_time) {
          self.pre_registration_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_start_time
          );
        }
        if (data.campaign_detail.pre_registration_end_time) {
          self.pre_registration_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.pre_registration_end_time
          );
        }
        if (data.campaign_detail.pre_registration_start_time_str) {
          self.pre_registration_start_time_str =
            data.campaign_detail.pre_registration_start_time_str;
        }
        if (data.campaign_detail.pre_registration_end_time_str) {
          self.pre_registration_end_time_str =
            data.campaign_detail.pre_registration_end_time_str;
        }
        if (data.campaign_detail.kick_off_start_time) {
          self.kick_off_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_start_time
          );
        }
        if (data.campaign_detail.kick_off_end_time) {
          self.kick_off_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.kick_off_end_time
          );
        }
        if (data.campaign_detail.kick_off_start_time_str) {
          self.kick_off_start_time_str =
            data.campaign_detail.kick_off_start_time_str;
        }
        if (data.campaign_detail.kick_off_end_time_str) {
          self.kick_off_end_time_str =
            data.campaign_detail.kick_off_end_time_str;
        }
        if (data.campaign_detail.gold_ball_start_time) {
          self.gold_ball_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_start_time
          );
        }
        if (data.campaign_detail.gold_ball_end_time) {
          self.gold_ball_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.gold_ball_end_time
          );
        }
        if (data.campaign_detail.gold_ball_start_time_str) {
          self.gold_ball_start_time_str =
            data.campaign_detail.gold_ball_start_time_str;
        }
        if (data.campaign_detail.gold_ball_end_time_str) {
          self.gold_ball_end_time_str =
            data.campaign_detail.gold_ball_end_time_str;
        }
        if (data.campaign_detail.graduation_start_time_str) {
          self.graduation_start_time_str =
            data.campaign_detail.graduation_start_time_str;
        }
        if (data.campaign_detail.graduation_end_time_str) {
          self.graduation_end_time_str =
            data.campaign_detail.graduation_end_time_str;
        }
        if (data.campaign_detail.graduation_start_time) {
          self.graduation_start_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_start_time
          );
        }
        if (data.campaign_detail.graduation_end_time) {
          self.graduation_end_time = Helper.convertFrom24To12Format(
            data.campaign_detail.graduation_end_time
          );
        }
        self.campaignType = data.campaign_detail.campaign_type;
        self.loading = false;
      };
      const failureHandler = function (res) {
        const data = res;
        console.log(data);
      };

      let formData = {};
      formData["campaign_id"] = this.campaignID;
      Axios.request_GET(
        API_ADMIN_CAMPAIGN_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    firsts() {
      this.first = true;
      this.second = false;
      this.third = false;
      this.fourth = false;
      this.fifth = false;
      this.sixth = false;
      this.tableFlag = true;
    },
    seconds() {
      this.first = false;
      this.second = true;
      this.third = false;
      this.fourth = false;
      this.fifth = false;
      this.sixth = false;
      this.tableFlag2 = true;
    },
    thirds() {
      this.first = false;
      this.second = false;
      this.third = true;
      this.fourth = false;
      this.fifth = false;
      this.sixth = false;
      this.tableFlag3 = true;
    },
    fourths() {
      this.first = false;
      this.second = false;
      this.third = false;
      this.fourth = true;
      this.fifth = false;
      this.sixth = false;
      this.tableFlag4 = true;
    },
    fifths() {
      this.first = false;
      this.second = false;
      this.third = false;
      this.fourth = false;
      this.fifth = true;
      this.sixth = false;
      this.tableFlag5 = true;
    },
    sixths() {
      this.first = false;
      this.second = false;
      this.third = false;
      this.fourth = false;
      this.fifth = false;
      this.sixth = true;
      this.tableFlag6 = true;
    },
  },
  mounted() {
    if (this.$route.query.campaign_id) {
      this.campaignID = this.$route.query.campaign_id;
      this.getCampaignDetail();
    }
    this.getSchoolDetails();
    this.getStateList();
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 315%;
  color: #ffffff;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: white;
  letter-spacing: 0px;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.cardActions {
  position: sticky;
  bottom: 0px;
  width: 500px;
  background-color: white;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}

.logo-preview {
  height: 100px;
  width: 200px;
  position: relative;
  bottom: 30px;
}
.logo-des {
  font-size: 12px;
  margin-left: 13px;
}

.file-input-col {
  margin-left: 13px;
}
img.logo {
  margin-left: 20px;
  max-height: 100px;
  max-width: 200px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}

.welcome-sectionmob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 10px;
  border-radius: 30px;
  width: 90%;
  font-size: 13px;
  margin-left: 15px;
  border: 1px solid rgb(44, 25, 99);
}

.step-discriptionmob {
  background: #fafafa;
  padding: 15px;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 1px 1px 5px #00000036;
  margin-top: -30px;
}

.timeline1 {
  font-size: 24px;
}
.timeline2 {
  font-size: 16px;
  font-weight: 500;
}
.header.pr-5.v-sheet.theme--light.v-toolbar.v-app-bar.v-app-bar--fixed.v-app-bar--is-scrolled {
  z-index: 10;
}
@media (min-width: 0px) and (max-width: 600px) {
  .v-card__title {
    width: 100%;
  }
  .cardActions {
    width: 100%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
.stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  margin-left: 14%;
}
.stepper-wrappermob {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -31%;
  width: 125%;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.stepper-itemmob {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}
.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}
.stepper-itemmob::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-itemmob::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}
.stepper-itemmob .step-countermob {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #ccc;
  margin-bottom: 6px;
}
.stepper-item.active {
  font-weight: bold;
}
.stepper-itemmob.active {
  font-weight: bold;
}
.stepper-item.completed .step-counter {
  background-color: #2c1963;
}
.stepper-itemmob.completed .step-countermob {
  background-color: #2c1963;
}
hr.new1 {
  border-top: 1px solid lightgray;
}
.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #2c1963;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}
.stepper-itemmob.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid #2c1963;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}
.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
.stepper-itemmob:first-child::before {
  content: none;
}
.stepper-itemmob:last-child::after {
  content: none;
}
.accor-leftmob {
  background: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  color: #7356e4;
  font-size: 11px;
  font-weight: 600;
  margin-left: -16px;
}
.accor-leftmob1 {
  background: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  color: #f9af45;
  font-size: 11px;
  font-weight: 600;
  margin-left: -16px;
}
.step-accordionmob {
  background: #7356e4;
  padding: 3px 17px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  color: white;
  height: 50px;
  font-size: 10px;
  position: relative;
  font-family: inter;
}
.step-accordionmob-second {
  background: #f9af45;
  padding: 3px 17px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  color: white;
  height: 50px;
  font-size: 10px;
  position: relative;
  font-family: inter;
}
@media screen and (min-width: 760px) {
  .welcome-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #2c1963;
    padding: 10px;
    border-radius: 30px;
    color: white;
    width: 75%;
    margin-left: 12%;
    font-size: 20px;
  }
}
@media (min-width: 1250px) {
  .welcome-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 10px;
    border-radius: 30px;
    width: 70%;
    margin-left: 15%;
    font-size: 27px;
    border: 1px solid rgb(44, 25, 99);
  }
}
@media screen and (min-width: 760px) {
  .completeSection {
    font-size: 20px;
    color: rgb(44, 25, 99);
    font-weight: 600;
    margin-left: 15%;
  }
}
@media screen and (min-width: 1250px) {
  .completeSection {
    font-size: 26px;
    color: rgb(44, 25, 99);
    font-weight: 600;
    margin-left: 30%;
  }
}
@media screen and (min-width: 760px) {
  .step-accordion {
    background: #7356e4;
    padding: 10px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
    color: white;
    height: 65px;
    width: 100%;
    margin-left: 1%;
    font-size: 15px;
    position: relative;
    font-family: inter;
  }
}
@media screen and (min-width: 760px) {
  .step-accordion-second {
    background: #f9af45;
    padding: 10px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
    color: white;
    height: 65px;
    width: 100%;
    margin-left: 1%;
    font-size: 15px;
    position: relative;
    font-family: inter;
  }
}
@media screen and (min-width: 760px) {
  .accor-left {
    background: #fff;
    padding: 16px 21px;
    border-radius: 50px;
    color: #7356e4;
    font-size: 13px;
    font-weight: 600;
    margin-left: -15px;
  }
}
@media screen and (min-width: 760px) {
  .accor-left1 {
    background: #fff;
    padding: 16px 21px;
    border-radius: 50px;
    color: #f9af45;
    font-size: 13px;
    font-weight: 600;
    margin-left: -15px;
  }
}
@media screen and (min-width: 760px) {
  .step-discription {
    background: #fafafa;
    padding: 15px;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 1px 1px 5px #00000036;
    width: 100%;
    margin-left: 1%;
    margin-top: -30px;
  }
}
@media screen and (min-width: 1250px) {
  .step-accordion {
    background: #7356e4;
    padding: 10px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
    color: white;
    height: 65px;
    width: 75%;
    margin-left: 12%;
    font-size: 30px;
    position: relative;
    font-family: inter;
  }
}
@media screen and (min-width: 1250px) {
  .step-accordion-second {
    background: #f9af45;
    padding: 10px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
    color: white;
    height: 65px;
    width: 75%;
    margin-left: 12%;
    font-size: 30px;
    position: relative;
    font-family: inter;
  }
}
@media screen and (min-width: 1250px) {
  .step-discription {
    background: #fafafa;
    padding: 15px;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 1px 1px 5px #00000036;
    width: 75%;
    margin-left: 12%;
    margin-top: -30px;
  }
}
@media screen and (min-width: 1250px) {
  .accor-left {
    background: #fff;
    padding: 16px 21px;
    border-radius: 50px;
    color: #7356e4;
    font-size: 29px;
    font-weight: 600;
    margin-left: -15px;
  }
}
@media screen and (min-width: 1250px) {
  .accor-left1 {
    background: #fff;
    padding: 16px 21px;
    border-radius: 50px;
    color: #f9af45;
    font-size: 29px;
    font-weight: 600;
    margin-left: -15px;
  }
}
@media only screen and (min-width: 768px) {
  .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    margin-left: -4%;
  }
}
@media only screen and (min-width: 768px) {
  .step-heading {
    width: 100%;
    margin-left: 15%;
  }
}
@media only screen and (min-width: 1250px) {
}
.step-heading {
  width: 75%;
}
</style>
